<template>
  <div>
    <el-dialog
      class="product-detail-dialog productDialog"
      width="1100px"
      top="2%"
      :title="title"
      :visible.sync="show"
      :close-on-click-modal="false"
    >
      <el-form
        style="margin: 0 auto"
        label-position="right"
        ref="formData"
        :inline="false"
        :model="formData"
        :rules="rules"
      >
        <div class="df">
          <div class="fl1 mgR16 productDialog-right">
            <el-form-item :label="$t('dc:产品名称')" prop="projectName" class="" required>
              <el-input
                maxlength="100"
                show-word-limit
                v-model="formData.projectName"
                :class="{ redFont: !showProductName }"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('dc:编码')" prop="projectCode" class="" required>
              <el-input
                maxlength="100"
                show-word-limit
                v-model="formData.projectCode"
                :class="{ redFont: !showProductCode }"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('dc:URL')" prop="projectUrl" class="" required>
              <el-input
                type="textarea"
                maxlength="100"
                resize="none"
                show-word-limit
                v-model="formData.projectUrl"
                :class="{ redFont: !showProductURL }"
                :autosize="{ minRows: 1, maxRows: 3 }"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('dc:产品类型')" prop="projectType" class="" required>
              <el-select
                style="width: 100%"
                v-model="formData.projectType"
                :class="{ redFont: !showProductType }"
                :placeholder="$t('dc:请选择')"
                @change="changeProjectType"
              >
                <el-option
                  v-for="item in productTypeOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="groupIdList">
              <template slot="label">
                <div style="width: 124px" :class="{ noSelect: formData.projectType !== 4 }">
                  {{ $t('dc:分配到组') }}
                </div>
              </template>
              <el-tooltip placement="top" :disabled="getGroupData.length == 0">
                <div slot="content" v-for="(item, index) in getGroupData" :key="index">
                  {{ item.name }}<br />
                </div>
                <el-select
                  style="width: 100%"
                  multiple
                  clearable
                  collapse-tags
                  filterable
                  v-model="formData.groupIdList"
                  :disabled="formData.projectType !== 4"
                  :placeholder="$t('dc:请选择')"
                >
                  <el-option
                    v-for="item in allGroupOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
            <el-form-item :label="$t('dc:归属')" prop="ownerId" class="" required>
              <el-select
                style="width: 100%"
                filterable
                remote
                v-model="formData.ownerId"
                :class="{ redFont: !showOwner }"
                :placeholder="$t('dc:请输入关键词')"
                :remote-method="remoteMethod"
              >
                <el-option
                  v-for="item in ownerOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('dc:描述')" prop="description">
              <el-input
                type="textarea"
                resize="none"
                show-word-limit
                v-model="formData.description"
                :class="{ redFont: !showProductURL }"
                :autosize="{ minRows: 5, maxRows: 5 }"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>
          </div>
          <div class="fl2 productDialog-left">
            <span class="font14 paddingB12">{{ $t('dc:功能和优点') }}</span>
            <!-- <el-form-item :label="$t('dc:使用')" prop="usage" class="">
              <el-input
                v-model="formData.usage"
                show-word-limit
                style="width: 100%"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item> -->
            <div class="df">
              <div class="fl1 mgR16">
                <span class="font12 paddingB12">{{ $t('dc:核心特征') }}</span>
                <el-form-item label="1" prop="coreFeaturesTitle1" :label-width="labelWidth">
                  <template slot="label">
                    <div class="labelTitle">1</div>
                  </template>
                  <el-input
                    resize="none"
                    show-word-limit
                    v-model="featureBenefitList['0'].title"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
                <el-form-item class="marginT10">
                  <el-input
                    type="textarea"
                    resize="none"
                    class="productDialog-textarea"
                    show-word-limit
                    v-model="featureBenefitList['0'].description"
                    :placeholder="$t('dc:请输入')"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                  ></el-input>
                </el-form-item>
                <el-form-item label="2" prop="coreFeaturesTitle2" :label-width="labelWidth">
                  <template slot="label">
                    <div class="labelTitle">2</div>
                  </template>
                  <el-input
                    resize="none"
                    show-word-limit
                    v-model="featureBenefitList[1].title"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
                <el-form-item class="marginT10">
                  <el-input
                    type="textarea"
                    resize="none"
                    class="productDialog-textarea"
                    show-word-limit
                    v-model="featureBenefitList[1].description"
                    :placeholder="$t('dc:请输入')"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                  ></el-input>
                </el-form-item>
                <el-form-item label="3" prop="coreFeaturesTitle3" :label-width="labelWidth">
                  <template slot="label">
                    <div class="labelTitle">3</div>
                  </template>
                  <el-input
                    resize="none"
                    show-word-limit
                    v-model="featureBenefitList[2].title"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
                <el-form-item class="marginT10">
                  <el-input
                    type="textarea"
                    resize="none"
                    class="productDialog-textarea"
                    show-word-limit
                    v-model="featureBenefitList[2].description"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="fl1">
                <span class="font12 paddingB12">{{ $t('dc:关键利益') }}</span>
                <el-form-item label="1" prop="keyBenefitsTitle1" :label-width="labelWidth">
                  <template slot="label">
                    <div class="labelTitle">1</div>
                  </template>
                  <el-input
                    resize="none"
                    show-word-limit
                    v-model="featureBenefitList[3].title"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
                <el-form-item class="marginT10">
                  <el-input
                    type="textarea"
                    resize="none"
                    class="productDialog-textarea"
                    show-word-limit
                    v-model="featureBenefitList[3].description"
                    :placeholder="$t('dc:请输入')"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                  ></el-input>
                </el-form-item>
                <el-form-item label="2" prop="keyBenefitsTitle3" :label-width="labelWidth">
                  <template slot="label">
                    <div class="labelTitle">2</div>
                  </template>
                  <el-input
                    resize="none"
                    show-word-limit
                    v-model="featureBenefitList[4].title"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
                <el-form-item class="marginT10">
                  <el-input
                    type="textarea"
                    resize="none"
                    class="productDialog-textarea"
                    show-word-limit
                    v-model="featureBenefitList[4].description"
                    :placeholder="$t('dc:请输入')"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                  ></el-input>
                </el-form-item>
                <el-form-item label="3" prop="keyBenefitsTitle3" :label-width="labelWidth">
                  <template slot="label">
                    <div class="labelTitle">3</div>
                  </template>
                  <el-input
                    resize="none"
                    show-word-limit
                    v-model="featureBenefitList[5].title"
                    :placeholder="$t('dc:请输入')"
                  ></el-input>
                </el-form-item>
                <el-form-item class="marginT10">
                  <el-input
                    type="textarea"
                    resize="none"
                    class="productDialog-textarea"
                    show-word-limit
                    v-model="featureBenefitList[5].description"
                    :placeholder="$t('dc:请输入')"
                    :autosize="{ minRows: 5, maxRows: 5 }"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="df">
              <div class="" style="padding-top: 6px">
                <span class="font12" style="font-weight: 500; line-height: 16px; color: #868686"
                  >{{ $t('dc:所有输入框都需要填写，否则无法提交。') }}
                </span>
                <span class="font12" style="line-height: 16px; margin-top: 6px"
                  ><a href="#" @click="showExampleStatus = true">{{
                    $t('dc:单击此链接查看示例。')
                  }}</a></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="df">
          <el-form-item :label="$t('dc:使用')" prop="usage" class="">
            <el-input
              v-model="formData.usage"
              maxlength="100"
              show-word-limit
              style="width: 350px"
              :placeholder="$t('dc:请输入')"
            ></el-input>
          </el-form-item>
          <div class="btn-box">
            <el-button class="" @click="show = false">{{ $t('dc:取消') }}</el-button>
            <el-button class="btn-black" type="info" @click="submitForm('formData')">{{
              $t('dc:确认')
            }}</el-button>
          </div>
        </div>
      </el-form>
      <!-- <span slot="footer">
        <el-button class="" @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button
          :disabled="!isEdit"
          class="btn-black"
          type="info"
          @click="submitForm('formData')"
          >{{ $t('dc:确认') }}</el-button
        >
      </span> -->
      <el-dialog
        width="30%"
        class="acctount-detail-dialog"
        :title="title"
        :visible.sync="innerVisible"
        :append-to-body="true"
      >
        <div class="fontW600">
          {{ productContentMsg }}
        </div>
        <div slot="footer">
          <el-button class="" @click="innerVisible = false">{{ $t('dc:否') }}</el-button>
          <el-button class="btn-black" type="info" @click="add4EditApp">{{
            $t('dc:是')
          }}</el-button>
        </div>
      </el-dialog>
      <SSOSubmitDialog
        v-if="showExampleStatus"
        :visible.sync="showExampleStatus"
        :dialogWith="'78.6%'"
        :title="$t('dc:示例')"
      >
        <div slot="body"><img style="height: 240px" :src="exampleImg" alt="" /></div>
        <span slot="footer"></span>
      </SSOSubmitDialog>
    </el-dialog>
  </div>
</template>

<script>
import confromProductDialog from '@/views/dialog/usuallyDialog'
import exampleImg from '@/assets/img/exampleImg.png'
import { productDialogMixins } from '@/assets/mixins/productDialogMixins'

export default {
  name: 'productDialog',
  mixins: [productDialogMixins],
  components: {
    confromProductDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => [],
    },
    submitData: {
      type: Array,
      default: () => [],
    },
    max: Number,
    title: {
      type: String,
      default: '',
    },
    rowData: {},
  },
  data() {
    var productNameRoute = (rule, value, callback) => {
      const rgExp = '^[A-Z0-9][A-Za-z0-9 ]*$'
      if (value == '') {
        this.showProductName = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (!new RegExp('[A-Z]').test(value)) {
        this.showProductName = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (!value.match(rgExp)) {
        this.showProductName = false
        callback(new Error("Only supports 'Blank'"))
      } else if (value.length > 100) {
        callback(new Error('Only support 100 characters'))
      } else {
        this.showProductName = true
        callback()
      }

      // else if (!new RegExp("^([A-Z][a-z]*[ ]?)+\\S$").test(value)) {
      //   this.showProductName = false;
      //   callback(new Error(rule.message || this.$t('dc:请按规则输入')));
      // }
    }
    var productCodeRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductCode = false
      } else {
        this.showProductCode = true
        callback()
      }
    }
    var productURLRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductURL = false
      } else if (
        !new RegExp(
          '^https?:\\/\\/(([a-zA-Z0-9_-])+(\\.)?)*(:\\d+)?(\\/((\\.)?(\\?)?=?&?[a-zA-Z0-9_-](\\?)?)*)*$',
        ).test(value)
      ) {
        this.showProductURL = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        this.showProductURL = true
        callback()
      }
    }
    var descriptionRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showProductName = false
      } else if (true) {
        this.showProductName = false
        callback(new Error(rule.message || this.$t('dc:请按规则输入')))
      } else {
        this.showProductName = true
        callback()
      }
    }
    var groupldsRule = (rule, value, callback) => {
      if (!!value && value.length == 0 && this.formData.projectType == 4) {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    return {
      exampleImg,
      loading: false,
      show: this.visible,
      innerVisible: false,
      showProductName: true,
      showProductCode: true,
      showProductURL: true,
      showProductType: true,
      showOwner: true,
      showExampleStatus: false,
      currentTypeConfig: {},

      labelWidth: '40px',
      formData: {
        projectName: '',
        projectCode: '',
        projectUrl: '',
        description: '',
        projectType: '',
        ownerId: '',
        groupIdList: [],
        usage: '',
      },
      featureBenefitList: [
        {
          orderId: 1,
          title: '',
          description: '',
        },
        {
          orderId: 2,
          title: '',
          description: '',
        },
        {
          orderId: 3,
          title: '',
          description: '',
        },
        {
          orderId: 4,
          title: '',
          description: '',
        },
        {
          orderId: 5,
          title: '',
          description: '',
        },
        {
          orderId: 6,
          title: '',
          description: '',
        },
      ],
      rules: {
        projectName: [
          {
            validator: productNameRoute,
            message: this.$t('dc:请按规则输入'),
            trigger: ['blur', 'change'],
          },
        ],
        projectCode: [{ validator: productCodeRoute, trigger: ['blur', 'change'] }],
        projectUrl: [{ validator: productURLRoute, trigger: ['blur', 'change'] }],
        description: [{ required: true, message: this.$t('dc:请按规则输入') }],
        projectType: [{ required: true, message: this.$t('dc:请选择') }],
        ownerId: [{ required: true, message: this.$t('dc:请选择'), trigger: ['change'] }],
        groupIdList: [
          {
            required: true,
            validator: groupldsRule,
            message: this.$t('dc:请选择'),
            trigger: ['change'],
          },
        ],
      },
      // productTypeOptions: [],
      ownerOptions: [],
      allGroupOptions: [],
      productContentMsg: this.$t('dc:您确认注册这个产品吗?'),
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    getGroupData() {
      if (this.formData.groupIdList.length > 0)
        return this.allGroupOptions.filter((item) => this.formData.groupIdList.includes(item.id))
      else return []
    },
    isEdit() {
      return (
        (!this.featureBenefitList[0].title &&
          !this.featureBenefitList[0].description &&
          !this.featureBenefitList[1].title &&
          !this.featureBenefitList[1].description &&
          !this.featureBenefitList[2].title &&
          !this.featureBenefitList[2].description &&
          !this.featureBenefitList[3].title &&
          !this.featureBenefitList[3].description &&
          !this.featureBenefitList[4].title &&
          !this.featureBenefitList[4].description &&
          !this.featureBenefitList[5].title &&
          !this.featureBenefitList[5].description) ||
        (!!this.featureBenefitList[0].title &&
          !!this.featureBenefitList[0].description &&
          !!this.featureBenefitList[1].title &&
          !!this.featureBenefitList[1].description &&
          !!this.featureBenefitList[2].title &&
          !!this.featureBenefitList[2].description &&
          !!this.featureBenefitList[3].title &&
          !!this.featureBenefitList[3].description &&
          !!this.featureBenefitList[4].title &&
          !!this.featureBenefitList[4].description &&
          !!this.featureBenefitList[5].title &&
          !!this.featureBenefitList[5].description)
      )
    },
  },
  mounted() {
    if (this.rowData.projectId) {
      this.getProductDetailData(this.rowData.projectId)
    }
    this.getGroupOption()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.isEdit) return this.$message.warning(this.$t('dc:所有输入框都需要填写.'))
          this.innerVisible = true
        } else {
          return false
        }
      })
    },
    add4EditApp() {
      if (!!this.rowData.projectId) this.editApp()
      else this.addNewApp()
    },

    changeProjectType() {
      if (this.formData.projectType !== 4) this.formData.groupIdList = []
    },
    async getGroupOption() {
      let { status, data } = await $Api.common.getAllGroupList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.allGroupOptions = data
      }
    },
    async addNewApp() {
      let { status, data, msg } = await $Api.project.addApp({
        ...this.formData,
        featureBenefitList: this.featureBenefitList,
      })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$emit('queryApi')
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      } else {
        this.innerVisible = false
      }
    },

    async getProductDetailData(projectId) {
      let { status, data } = await $Api.project.getProductDetail({ projectId: projectId })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.formData = data
        if (this.formData.groupIdList == null) this.formData.groupIdList = []
        this.ownerOptions = data.owner
        if (data.featureBenefitList !== null) {
          data.featureBenefitList.forEach((item, index) => {
            this.featureBenefitList[index].title = item.title
            this.featureBenefitList[index].description = item.description
          })
        }
      }
    },
    async editApp() {
      let { status, data, msg } = await $Api.project.editApp({
        ...this.formData,
        featureBenefitList: this.featureBenefitList,
      })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$emit('queryApi')
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      } else {
        this.innerVisible = false
      }
    },

    async remoteMethod(query) {
      if (query !== '') {
        let { status, data } = await $Api.project.getOwnerOptionList({ username: query })
        if (status === this.$RESPONSE_STATUS.SUCCESS) {
          this.ownerOptions = data
        }
      }
    },
  },
}
</script>
<style lang="scss">
.product-detail-dialog {
  .el-form-item__label {
    font-size: 14px;
  }
  .mgR16 {
    margin-right: 16px;
  }
  // .el-dialog__footer {
  //   padding: 0;
  // }
  // .el-textarea textarea.el-textarea__inner {
  //   height: 64px !important;
  //   min-height: 64px !important;
  // }
}
.productDialog {
  &-left {
    span {
      display: block;
      color: #000;
      font-weight: bold;
    }
    span a {
      text-decoration: underline;
      color: #43aa00;
    }
    .labelTitle {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      background: #d2fcc1;
      border-radius: 4px;
    }
  }
  .btn-box {
    height: 62px;
    display: flex;
    align-items: flex-end;
    .el-button {
      width: 92px;
    }
  }
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    float: right;
  }
  .productDialog-textarea .el-textarea textarea.el-textarea__inner {
    height: 400px;
  }
}

.df {
  display: flex;
  justify-content: space-between;
}
.fl2 {
  flex: 2;
}
.fl1 {
  flex: 1;
}
.marginT10 {
  margin-top: -8px;
}
.paddingB12 {
  padding-bottom: 16px;
}
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.fontW600 {
  font-weight: 600;
  color: #000;
}
.noSelect {
  color: #c0c4cc;
}
</style>
