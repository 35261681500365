<template>
  <div class="user-login login-scale user-login-mobile">
    <img src="/static/mebile-login-bg.png" class="user-login-mobile-bg" />
    <!-- <div></div> -->
    <div class="logBox">
      <div></div>
      <!-- <img class="logLion" src="../assets/img/log.png" alt> -->
      <img class="logo" src="../assets/img/logLion.png" alt />
    </div>
    <div class="login-box">
      <dl class="login-box-card">
        <dt class="login-box-header">
          <h2 class="login-box-header-welcome">WELCOME</h2>
          <div class="tab-box">
            <el-tabs v-model="activeName" :stretch="true" @tab-click="handleClick">
              <el-tab-pane label="LION LOGIN" name="first"></el-tab-pane>
              <el-tab-pane label="ACCOUNT LOGIN" name="second"></el-tab-pane>
            </el-tabs>
          </div>
          <!-- <img class="login-box-logo" :src="`${staticPath}/logo_main.gif`" />
          <img :src="`${staticPath}/logo_title.png`" />-->
        </dt>
        <dd class="login-box-body">
          <transition name="moveR">
            <div v-if="activeName == 'first'" class="login-box-body-item">
              <div class="login-box-row lionid">
                <el-button
                  size="small"
                  class="user-login-btn btn-green"
                  type="primary"
                  @click="goLionid"
                  >LOGIN WITH LION ID</el-button
                >
              </div>
            </div>
          </transition>
          <transition name="moveL">
            <div
              v-if="activeName == 'second'"
              class="login-box-body-item"
              style="position: absolute; top: 0; left: 0; width: 100%"
            >
              <div class="login-box-row">
                <el-input
                  size="small"
                  placeholder="account/e-mail"
                  prefix-icon="iconfont icon-oneui-user-thick"
                  v-model="userInfo.account"
                />
              </div>
              <div class="login-box-row">
                <el-input
                  show-password
                  autocomplete="new-password"
                  size="small"
                  placeholder="password"
                  prefix-icon="iconfont icon-oneui-pass-thick"
                  v-model="userInfo.password"
                  @keyup.enter.native="onLogin"
                />
              </div>
              <div class="login-box-row sumbit">
                <el-button
                  size="small"
                  type="primary"
                  class="user-login-btn btn-green"
                  @click="onLogin"
                  >Sign In
                </el-button>
                <div>
                  <a href="javascript:;" @click.stop="goApply">Create Account</a>
                  <a href="javascript:;" @click.stop="goPassword">Forget Password?</a>
                </div>
              </div>
            </div>
          </transition>
        </dd>
      </dl>
    </div>

    <div class="record">
      2018 - {{ nowYear }} PublicisGrowthOS.
      <img class="icpImg" src="@/assets/img/icpicon.png" alt="" />
      <a
        style="margin-right: 12px"
        href="https://beian.mps.gov.cn/#/query/webSearch?code=31010602008641"
        rel="noreferrer"
        target="_blank"
        >沪公网安备31010602008641号</a
      >
      <!-- <img class="icpImg" src="@/assets/img/icpicon.png" alt="" /> -->
      <a href="https://beian.miit.gov.cn" target="_blank"> 沪ICP备2021007762号-1</a>
    </div>
    <el-dialog
      :visible.sync="show"
      width="90%"
      top="20%"
      title="Reminder"
      :close-on-click-modal="false"
      class="acctount-detail-dialog"
    >
      <!-- <div slot="title" class="header-box">
        <span>Reminder</span>
      </div> -->
      <div>
        Your appication is in progress,<br />
        please wait for approval.
      </div>
      <div slot="footer">
        <el-button type="primary" @click="show = false">OK</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showChangePassword"
      width="90%"
      top="20%"
      title="Reset Password"
      :close-on-click-modal="false"
      class="acctount-detail-dialog home"
    >
      <el-form
        :model="resetDateForm"
        ref="resetDateForm"
        :rules="rulesList"
        hide-required-asterisk
        label-width="60px"
        label-position="right"
        class="demo-dynamic"
        style="margin-bottom: 0"
      >
        <el-form-item label-width="60px" label="Name" prop="username" class="marginB50">
          <el-input
            class="input-black"
            v-model="resetDateForm.username"
            maxlength="100"
            show-word-limit
          ></el-input>
          <span v-show="showName" class="el-form-item__error fontS12"
            >Please input your name as exampled: MurielLiu</span
          >
        </el-form-item>
        <el-form-item label-width="60px" prop="email" label="E-mail" class="">
          <el-input
            class="input-black"
            v-model="resetDateForm.email"
            maxlength="100"
            show-word-limit
          ></el-input>
          <span v-show="showEmail" class="el-form-item__error fontS12">xxxxxxx@xxxx.com</span>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button class="btn-green" type="primary" @click="changPassword('resetDateForm')"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <accountPassword
      v-if="dialogAccoutPassword"
      :visible.sync="dialogAccoutPassword"
      :accountname="userData.username"
      @changePassword="changePassword"
    ></accountPassword>
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import config from '@/api/config'
import { mapActions } from 'vuex'
const { VUE_APP_BASEURL } = process.env
import accountPassword from './dialog/accountPassword'
import createRsa from '@/utils/encryption'
export default {
  name: 'UserLogin',
  components: {
    accountPassword,
  },
  data() {
    var nameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showName = false
      } else if (!new RegExp('[A-Z]').test(value)) {
        this.showName = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        this.showName = true
        callback()
      }
    }
    var emailRoute = (rule, value, callback) => {
      const mailReg = '\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*'
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showEmail = false
      } else if (value.length > 100) {
        this.showEmail = false
        callback(new Error('Only support 100 characters;'))
      }
      setTimeout(() => {
        if (new RegExp(mailReg).test(value)) {
          this.showEmail = true
          callback()
        } else {
          this.showEmail = false
          callback(new Error(this.$t('dc:请按规则输入')))
        }
      }, 100)
    }
    return {
      activeName: 'first',
      staticPath: `/static`,
      userInfo: {
        account: '',
        password: '',
        callbackUrl: '',
        appName: '',
      },
      show: false,
      showName: true,
      showEmail: true,
      showChangePassword: false,
      resetDateForm: {
        username: '',
        email: '',
      },
      rulesList: {
        username: [{ validator: nameRule, trigger: 'change' }],
        email: [{ validator: emailRoute, trigger: 'blur' }],
      },
      userData: '',
      dialogAccoutPassword: false,
      nowYear: '',
    }
  },

  mounted() {
    this.$cookies.remove('login_token')
    this.$cookies.remove('userToken')
    this.userInfo.callbackUrl = this.$route.query.callbackUrl || window.location.href
    this.userInfo.appName = this.$route.query.appName || this.$PROJECT_CODE
    let nowDate = new Date()
    this.nowYear = nowDate.getFullYear()
    // document.getElementById('video-tvc').play();
  },
  methods: {
    ...mapActions(['updateUserInfo']),
    async onLogin() {
      let userParams = {
        account: this.userInfo.account,
        password: createRsa(this.userInfo.password),
        callbackUrl: this.userInfo.callbackUrl,
        appName: this.userInfo.appName,
      }
      const { data, status, msg } = await $Api.user.login(userParams)
      if (status == 0) {
        this.$gtag &&
          this.$gtag.config({
            send_page_view: false,
            lionId: data.lionId,
          })
        this.userData = data
        this.updateUserInfo(data)
        this.$localStorage.setItem('lang', data.lang)
        let token = data.callbackUrl.split('token=')[1]
        this.$sessionStorage.setItem(config.USER_TOKEN_KEY, token)
        // this.$cookies.set(config.COOKIE_TOKEN, token);
        const callbackUrl = data.callbackUrl
        this.loginCountShowPassword(data)
      } else {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error',
        })
      }
    },
    async loginCountShowPassword(params) {
      const { status, data } = await $Api.user.loginCount(params.username)
      if (status == config.RESPONSE_STATUS.SUCCESS && data.isFirstTime) {
        this.dialogAccoutPassword = true
      } else {
        window.location.href = params.callbackUrl
      }
    },
    goLionid() {
      const paramStr = `callbackUrl=${encodeURIComponent(this.userInfo.callbackUrl)}&appName=${
        this.userInfo.appName
      }`
      window.location.href = `${$Api.user.goLionid()}?${paramStr}`
    },
    // createRsa(password) {
    //   let jsencrypt = new JSEncrypt();
    //   jsencrypt.setPublicKey(config.AESPUBLIC_KEY);
    //   let rsaData = jsencrypt.encrypt(password);
    //   return encodeURIComponent(rsaData);
    // },
    handleClick(tab, event) {},
    goApply() {
      this.$router.push({ name: 'user.Apply' })
    },
    goPassword() {
      this.showChangePassword = true
    },
    changPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changForgetPwd(this.resetDateForm)
        } else {
          return false
        }
      })
    },
    async changForgetPwd(params) {
      const { status, msg } = await $Api.user.forgetPwd(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.resetDateForm.username = ''
        this.resetDateForm.email = ''
        this.$message({
          showClose: false,
          message: msg || this.$t('dc:已发送重置邮件给到管理员，请等待审批邮件通知！'),
          type: 'success',
        })
      }
      this.showChangePassword = false
    },
    async changePassword(data) {
      this.userInfo.password = ''
      this.dialogAccoutPassword = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/var.scss';
$text-color: #fff;

.user-login-mobile {
  // background: #000!important;
  // background-image: url('/static/mebile-login-bg.png');
  // background-position: center center;
  // background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;

  .user-login-mobile-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    // transform: translate(0, -50%);
  }

  .login-box {
    top: 62% !important;
  }

  .login-box-header-welcome {
    font-size: 56px;
    color: #fff;
    margin: 26px 0;
    font-weight: 500;
  }

  .login-box-body {
    width: 100% !important;

    .login-box-row {
      // padding: 0 2px;
    }
  }

  .record {
    bottom: 3%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      margin: 0 4px 0 6px;
    }

    a {
      display: flex;
      align-items: center;
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active,
    a:focus {
      color: #fff;
    }
  }
}
</style>
