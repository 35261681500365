<template>
  <div class="publicis-data-center">
    <poc-container class="sso-container">
      <template slot="logo">
        <img :src="allLogoImg" style="height: 54px" />
        <!-- <img :src="logoImg" style="height:38px;margin-top: -10px;"> -->
      </template>
      <ProtailHeaderRightGroup
        slot="rightGroup"
        :showUser="showUser"
        :showManagerStatus="showManagerStatus"
        :dialogVisible="dialogVisible"
        :showModel="showModel"
        @showpassword="showpassword"
        @closeGroup="closeDelog"
        @showGroupManager="showGroupManager"
        @changeAgency="changeAgency"
        @showGroupinfomation="showGroupInfomation"
        @showUserFreedBack="showUserFreedBack"
        @closeHeader="closeHeader"
      />
      <div class="publicis-data-center-body">
        <div class="publicis-data-center-body-project">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-card class="spoc-bg">
                <div class="spoc-box">
                  <div class="title">{{ edageAiData.title }}</div>
                  <div class="spoc-content">
                    <div
                      class="edge-content"
                      v-html="lang == 'en-us' ? edageAiData.content.en : edageAiData.content.cn"
                    ></div>
                  </div>
                  <el-button
                    class="btn-green btn-portal"
                    :class="{ noAccess: !showChatIcon }"
                    type="primary"
                    @click="clickNum"
                    >Enter EDGE AI</el-button
                  >
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="homeAppMarginTop">
            <el-col :span="8">
              <appCardTmp
                :typeId="1"
                :dataMsg="homeTeamGroupData.right"
                :appList="appList"
                :type="homeClass"
                @showProductDrawer="showProductDrawer"
              ></appCardTmp>
            </el-col>
            <el-col :span="11">
              <appCardTmp
                :typeId="3"
                :dataMsg="homeTeamGroupData.work"
                :appList="appList"
                :type="homeClass"
                @showProductDrawer="showProductDrawer"
              ></appCardTmp>
            </el-col>
            <el-col :span="5">
              <appCardTmp
                :typeId="2"
                :dataMsg="homeTeamGroupData.magic"
                :appList="appList"
                :type="homeClass"
                @showProductDrawer="showProductDrawer"
              ></appCardTmp>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20" class="poc-mt16">
            <el-col :span="24">
              <carouselCardTmp :typeId="3" :dataMsg="homeTeamGroupData.work" :widthL="widthL" :appList="appList"
                :type="homeClass" :className="bigImg" @showProductDrawer="showProductDrawer"></carouselCardTmp>
            </el-col>

          </el-row> -->
          <el-row :gutter="20" class="homeAppMarginTop">
            <el-col :span="14">
              <carouselCardTmp
                :isCommonData="isCommonData"
                :dataMsg="homeTeamGroupData.commonData"
                :appList="appList"
                :type="homeClass"
                @showProductDrawer="showProductDrawer"
              ></carouselCardTmp>
            </el-col>
            <el-col :span="10">
              <el-card class="carousel-bg mglass">
                <div class="carousel-explain">
                  <span class="title">{{ homeTeamGroupData.skill }}</span>
                </div>

                <el-row class="dpFlex skill">
                  <el-col :span="10">
                    <div class="dpFlex JCC">
                      <div class="cursorPointer one" @click="showResouces">
                        <div class="mrclImg">
                          <!-- <span class="poc-size16">3RD PARTY RESEARCH</span> -->
                          <img :src="RDPARTYRESEARCH" alt="" style="" />
                        </div>
                        <span class="subTitle">Industry Subscribed Data Sources</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="dpFlex JCC">
                      <div class="cursorPointer two" @click="goMrcl">
                        <div
                          class="mrclImg ohter"
                          style="
                            display: block;
                            border-right: 1px solid #ffffff;
                            border-left: 1px solid #ffffff;
                          "
                        >
                          <img :src="marcel" alt="" />
                        </div>
                        <span class="subTitle">Upskill & Training</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="dpFlex JCC">
                      <div class="cursorPointer three" style="cursor: not-allowed">
                        <div class="campaign">
                          <img :src="campaign" alt="" class="" />
                        </div>
                        <span class="subTitle">Case Studies</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </poc-container>

    <!-- <drag v-if="showChatIcon" @dropped="handleDropped($event)" >
    <div class="chatGPTBox container animated" @dblclick=" showChatGPTDialog = true">
          <img :src="chatImg" alt="">
        </div>
    </drag> -->

    <accountPassword
      v-if="dialogAccoutPassword"
      :visible.sync="dialogAccoutPassword"
      :accountname="userName"
      @changePassword="changePassword"
    ></accountPassword>
    <div>
      <usuallyDialog
        v-if="show4Dialog"
        :visible.sync="show4Dialog"
        :title="groupTitle"
        :content="groupContent"
        :className="homeClass"
        :noClass="classNo"
        @close="closeGroup"
        @confrom="confrom"
      ></usuallyDialog>
      <userManagerListDialog
        v-if="showUserManagerListDialog"
        :visible.sync="showUserManagerListDialog"
        :appProductId="appIndexId"
        @groupMager="groupMager"
        @getNotify="getNotify"
      ></userManagerListDialog>
      <userManagerDialog
        v-if="showUserManagerDialog"
        :visible.sync="showUserManagerDialog"
        :managerGroupId="groupManagerId"
        :managerGroupName="groupName"
        @closeManager="closeManagerDialog"
        @getNotify="getNotify"
      ></userManagerDialog>
      <questionnaireDialog
        v-if="show4Questionnaire"
        :visible.sync="show4Questionnaire"
        :className="homeClass"
        :noClass="classNo"
        :researchQuestData="researchQuestData"
        @closeQuestion="closeQuestion"
        @confromQuestion="confromQuestion"
      ></questionnaireDialog>
      <changeBrandDialog
        v-if="showChangeBrand"
        :visible.sync="showChangeBrand"
        @changePassword="changePassword"
      >
      </changeBrandDialog>
      <agencyListDialog
        v-if="showAgencyDialog"
        :visible.sync="showAgencyDialog"
        @closeAgency="closeAgency"
      >
      </agencyListDialog>
      <infomationDialog v-if="showInfomation" :visible.sync="showInfomation"> </infomationDialog>
      <goLinkDialog
        v-if="showGoLinkDialog"
        :visible.sync="showGoLinkDialog"
        :itemData="itemData"
      ></goLinkDialog>
      <userFreedBackDialog
        v-if="dialogUserFreedBack"
        :visible.sync="dialogUserFreedBack"
      ></userFreedBackDialog>
      <intelligenceDialog
        v-if="showIntelligenceDialog"
        :visible.sync="showIntelligenceDialog"
        :className="homeClass"
      >
      </intelligenceDialog>
      <productDrawerDialog
        :visible.sync="shwoproductDrawerDialog"
        :appIndexId="appIndexId"
        @showProductGroupManager="showProductGroupManager"
      ></productDrawerDialog>
      <chatGPTDialog :visible.sync="showChatGPTDialog"></chatGPTDialog>
    </div>
  </div>
</template>

<script>
// import bgp4 from '@/assets/video/login.mp4'
const { VUE_APP_AI } = process.env
import allLogoImg from '@/assets/img/allLogoImg.png'
import logoImg from '@/assets/img/ssoEdge.png'
import logLion from '@/assets/img/logo.png'
import RDPARTYRESEARCH from '@/assets/img/3RDPARTYRESEARCH.png'
import campaign from '@/assets/img/campaign1.png'
import marcel from '@/assets/img/marcel.png'

import chatImg from '@/assets/img/chatImg.png'

import config from '@/api/config'
import getAllData from '@/utils/getApplist'
import getNoData from '@/utils/getNoApp'

import { mapGetters, mapActions } from 'vuex'
import ProtailHeaderRightGroup from '@/components/ProtailHeaderRightGroup'
import accountPassword from './dialog/accountPassword'
import usuallyDialog from '@/views/dialog/usuallyDialog'
import userManagerListDialog from '@/views/dialog/userManagerListDialog'
import userManagerDialog from '@/views/dialog/userManagerDialog'
import questionnaireDialog from '@/views/dialog/questionnaireDialog'
import changeBrandDialog from '@/views/dialog/changeBrandDialog'
import agencyListDialog from '@/views/dialog/agencyListDialog'
import infomationDialog from '@/views/dialog/infomationDialog'
import goLinkDialog from '@/views/dialog/goLinkDialog'
import carouselTmp from './components/carouselTmp.vue'
import carouselCardTmp from './components/carouselCardTmp.vue'

import appCardTmp from './components/appCardTmp.vue'

import intelligenceDialog from '@/views/dialog/intelligenceDialog'
import userFreedBackDialog from '@/views/dialog/userFreedBackDialog'
import productDrawerDialog from '@/views/dialog/productDrawerDialog'

import chatGPTDialog from '@/views/dialog/chatGPTDialog'
import drag from '@/views/components/dragItDude'
export default {
  name: 'home',
  components: {
    ProtailHeaderRightGroup,
    accountPassword,
    usuallyDialog,
    userManagerListDialog,
    userManagerDialog,
    questionnaireDialog,
    changeBrandDialog,
    agencyListDialog,
    infomationDialog,
    goLinkDialog,
    carouselTmp,
    carouselCardTmp,
    appCardTmp,
    intelligenceDialog,
    userFreedBackDialog,
    productDrawerDialog,
    chatGPTDialog,
    drag,
  },
  data() {
    return {
      logoImg,
      logLion,
      allLogoImg,
      RDPARTYRESEARCH,
      campaign,
      marcel,
      // mrcl,
      // RESOURCES,
      // MARCEL2,
      // CAMPAIGN,
      chatImg,
      showUser: false,
      // 显示通用提示
      show4Dialog: false,
      // 检测首页用户红点信息展示
      dialogVisible: false,
      // 显示agency媒介列表
      showAgencyDialog: false,
      showUserManagerListDialog: false,
      showUserManagerDialog: false,
      // 显示修改密码
      dialogAccoutPassword: false,
      // 提示jra与ci
      showGoLinkDialog: false,
      // 项目版本控制
      showAppLinkDialog: false,
      // 现实产品详情
      shwoproductDrawerDialog: false,
      appList: [],
      noShowApp: [16, 35, 34],
      applyProduct: {},
      groupTitle: '',
      groupContent: '',
      homeClass: 'home',
      classNo: '',
      userName: '',
      groupName: '',
      bigImg: 'bigImg',
      groupManagerId: 0,
      titleList: [],
      // 显示权限申请提示
      showModel: false,
      // 个人信息数字检测状态
      showManagerStatus: true,
      // 显示问卷调查
      show4Questionnaire: false,
      showChangeBrand: false,
      showInfomation: false,
      dialogUserFreedBack: false,
      // 智能信息
      showIntelligenceDialog: false,
      isCommonData: true,
      widthL: true,
      iconImgBox: [],
      itemData: '',
      linkList: [],
      researchQuestData: {},
      carouseHeight: '88px',
      carouselIndex: 0,
      activeIndex: 0,
      spocData: {
        title: 'GET EDGE',
        content:
          'Enablers of Disruptive Growth across Everything. The Publicis EDGE team is the middle platform at the heart of the Publicis Groupe in China, delivering on our clients’ growth challenges across Data & Technology, Data Solutions, Growth Planning, Analytics and Strategic Solutions.Use this form to submit a brief to our specialists, letting us know your requirements, and we will get to work on your winning EDGE.',
      },
      edageAiData: {
        title: 'EDGE AI',
        content:
          'EDGE AI is a comprehensive intelligent platform that leverages advanced AI models, integrates specific business requirements and real-word application scenarios, and assists users in accomplishing various tasks, significantly enhancing work efficiency and innovation capabilities',
      },
      appIndexId: '',
      appProductId: '',
      showChatGPTDialog: false,
      spocAccess: false,
      appCardLoading: true,
      showChatIcon: false,
      // homeTeamGroupData:{
      //   right:'MAKE IT RIGHT',
      //   magic:'MAKE IT MAGIC',
      //   work:'MAKE IT WORK',
      //   commonData:[
      //    { title:'My Favourite'},
      //    { title:'Team App' }
      //   ],
      //   skill:'KNOWLEDGE & SKILL CE'
      // }
    }
  },
  watch: {
    showUserManagerListDialog(v) {
      // if (!v) this.getList();
      if (!v && this.groupManagerId !== 0) {
        this.showUserManagerDialog = true
      }
    },
    showUserManagerDialog(v) {
      if (!v) this.showUserManagerListDialog = true
    },
  },
  filters: {
    toUpper: (content) => {
      return content.toUpperCase()
    },
  },
  created() {
    this.iconImgBox = getAllData()
    this.initEvent()
  },
  computed: {
    ...mapGetters(['userInfo', 'lang']),
    homeTeamGroupData() {
      return {
        right: {
          title: this.$t('dc:正确使用'),
          contentTitle: 'Develop the right strategy to unlock growth: ',
          contentMain:
            ' Focus on finding the right insights to define future-proof strategies fuelled by data, that will deliver against the challenge and unlock growth',
        },
        magic: {
          title: this.$t('dc:使其魔幻'),
          contentTitle: 'Create dynamic experiences that impact behaviour: ',
          contentMain:
            'Engage consumers across channels and platforms, creating unique experiences which bring brands to life and activate growth',
        },
        work: {
          title: this.$t('dc:使其工作'),
          contentTitle: 'Execute brilliantly and continuously improve: ',
          contentMain:
            'Orchestrate experiences into effective, scaled execution. Continuously optimize and gather learnings to fuel future strategies and planning ',
        },
        commonData: [{ title: this.$t('dc:团队APP') }, { title: this.$t('dc:常用的') }],
        skill: this.$t('dc:知识技能'),
      }
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    ...mapActions(['clearUserInfo', 'updateUserInfo']),
    async initEvent() {
      // this.getList();
      this.getAuthorityMsgData()
      this.initUserStatus()
      this.getGroupMsgData()
      this.get4ChangerReach({ appName: this.$PROJECT_CODE })
      this.getProjectDetailData()
    },
    // 获取用户详情
    async initUserStatus() {
      this.userName = this.userInfo.fullName
      if (this.userInfo.supAdmin === this.$RESPONSE_STATUS.SUPUSER) this.showUser = true
      // if (
      //   this.userInfo.agencyId === null &&
      //   this.userInfo.lionId !== null &&
      //   this.userInfo.lionId !== ''
      // )
      //   this.showAgencyDialog = true
    },

    // 查询是否要弹出group的提示信息
    async getGroupMsgData() {
      let { status, data } = await $Api.group.getGroupMsg()
      if (status === this.$RESPONSE_STATUS.SUCCESS && data.adminMsg !== null) {
        this.groupTitle = 'Reminder'
        this.groupContent = data.adminMsg
        this.classNo = 'homeon'
        this.show4Dialog = true
      }
    },
    async getList() {
      let { status, data } = await $Api.project.applist()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.iconImgBox.map((iconItem) => {
          data.map((appItem) => {
            if (iconItem.id === appItem.id) {
              appItem.iconimgUrl = iconItem.iconimgUrl
            }
          })
        })
      }
      if (data) this.appList = data
    },

    async applyOption(item) {
      this.noShowApp = getNoData()
      let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      if (item.link && token) {
        if (item.name === 'Publicis CI' || item.name === 'Jira') {
          this.showGoLinkDialog = true
          return (this.itemData = `${item.link}`)
        }
        if (item.links && item.links.length > 1) {
          this.showAppLinkDialog = true
          this.linkList = item.links
          this.$sessionStorage.setItem('appLinkList', item.links)
          this.$router.push({ name: 'applink' })
          return
        }
        return (window.location.href = `${item.link}?token=${token}`)
      } else {
        this.getList()
        this.showModel = true
      }
    },

    groupMager(row) {
      this.groupName = row.groupName
      this.groupManagerId = row.groupId
      this.showUserManagerListDialog = false
      this.showUserManagerDialog = false
    },
    confrom() {
      this.getGroupMsgData()
      this.show4Dialog = false
    },
    changeManagerStatus() {
      this.showManagerStatus = !this.showManagerStatus
    },
    showpassword() {
      this.dialogAccoutPassword = true
    },
    showUserFreedBack() {
      this.dialogUserFreedBack = true
    },
    showProductDrawer(val) {
      this.appIndexId = val.id
      this.shwoproductDrawerDialog = true
    },
    changePassword() {
      this.clearUserInfo()
      this.$router.push({ name: 'login' })
    },
    showGroupInfomation() {
      this.showInfomation = true
    },
    changeAgencyId(item) {
      this.agencyId = item.id
    },
    showGroupManager() {
      this.appIndexId = ''
      this.showModel = false
      this.showUserManagerListDialog = true
    },
    showProductGroupManager(val) {
      this.appProductId = val.id
      this.showModel = false
      this.showUserManagerListDialog = true
    },
    showResouces() {
      this.showIntelligenceDialog = true
    },
    goMrcl() {
      window.open(config.MRCL_URL, '_blank')
    },
    changeAgency() {
      if (this.userInfo.lionId !== '') this.showAgencyDialog = true
      else this.showChangeBrand = true
    },
    changeTeam(index) {
      this.activeIndex = index
    },
    async get4ChangerReach(params) {
      let { status, data } = await $Api.user.researchQuest(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          this.researchQuestData = data
          this.show4Questionnaire = true
        } else this.show4Questionnaire = false
      }
    },
    confromQuestion() {
      this.show4Questionnaire = false
    },
    closeQuestion() {
      this.show4Questionnaire = false
    },
    closeDelog() {
      this.showModel = false
    },
    closeGroup() {
      this.getGroupMsgData()
    },
    async closeAgency() {
      this.showAgencyDialog = false
      this.agencyId = this.userInfo.agencyId
    },
    getNotify() {
      this.showManagerStatus = !this.showManagerStatus
    },
    closeManagerDialog() {
      this.showUserManagerListDialog = true
    },
    closeHeader() {
      this.showModel = false
    },
    async getAuthorityMsgData() {
      let { status, data } = await $Api.chatGPT.getAuthority()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.showChatIcon = data.authorized
      }
    },
    async clickNum() {
      if (!this.showChatIcon) return
      await $Api.user.loginNum({ productId: 50 })
      this.goEDGEAI()
    },
    async getProjectDetailData() {
      const { status, data } = await $Api.project.getEdgeAiDoc()
      if (status == config.RESPONSE_STATUS.SUCCESS) {
        this.edageAiData.content = data
      }
    },
    goSpoc() {
      let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      window.open(process.env.VUE_APP_SPOCURL + '/?token=' + token, '_blank')
    },
    goEDGEAI() {
      let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      return window.open(`${VUE_APP_AI}?token=${token}`, '_blank')

      // let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      // window.open(process.env.VUE_APP_AI + '/?token=' + token, '_blank')
    },
    handleDropped(e) {},
  },

  destroyed() {},
}
</script>

<style lang="scss">
@media screen and (max-width: 1366px) {
  @import '@/assets/css/portal.scss';
}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  @import '@/assets/css/portalSmallMin.scss';
}

@media screen and (min-width: 1441px) and (max-width: 1553px) {
  @import '@/assets/css/portalMin.scss';
}

@media screen and (min-width: 1553px) and (max-width: 1920px) {
  @import '@/assets/css/portalPuls.scss';
}

@media screen and (min-width: 1921px) {
  @import '@/assets/css/portalBigPuls.scss';
}
// @media screen and (min-height:1553px) and (min-height:400px){
//   @import "@/assets/css/portalHeightBigPuls.scss";
// }
.edge-content strong {
  font-weight: 700;
}
.sso-container .poc-body .el-main {
  margin: 0px;
  overflow: hidden;
  // background: url(../assets/img/pb-bg2.png) no-repeat;
  background: url(../assets/img/mglass2.jpg) no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  object-fit: cover;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0px solid #000;
  // box-shadow: inset 80px 120px 120px -14px #453E3A, inset -80px 120px 120px 14px #453E3A;
}
.publicis-data-center-body .btn-green.el-button.noAccess {
  background: #ccc;
  cursor: not-allowed;
  border-color: transparent;
}
.sso-container .poc-header {
  width: 100%;
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  display: flex;
  z-index: 10;
  // justify-content: space-around;
  height: 80px !important;
  padding: 20px 30px 20px 30px;
}

.division2 span {
  display: block;
  width: 220px;
  word-wrap: break-word;
}

.publicis-data-center {
  height: 100%;
  background: #000;

  .sso-container {
    background: transparent;
    position: relative;
    z-index: 2;
  }

  .login-video {
    // position: absolute;
    // z-index: 1;
    // top: 0;
    // left: 0;
    // overflow: hidden;
    // // width: 100%;
    // height: 110%;
    // object-fit: fill;

    /*这里是关键*/
    .video-tvc {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      // object-fit: fill;
      // height: 100%;
      // width: 100%;
      // background: url(../assets/img/pb-bg.png);
      object-fit: cover;
      /*这里是关键*/
    }
  }
}

// .chatGPTBox{
//   position: fixed;
//   bottom: 20%;
//   right: 3%;
//   z-index: 10;
//   img{
//     display: block;
//     width: 56px;
//     height: 56px;
//     cursor: pointer;
//   }
// }

.chatGPTBox {
  position: fixed;
  z-index: 100;
  // // bottom: 18%;
  // // right: 3%;
  // z-index: 10;
  // transform: translate(-50%, -50%);
  // text-align: center;
  // margin-right: -90%;
  // animation-name: beat;
  // /*动画名称*/
  // animation-duration: .8s;
  // /*设置秒数*/
  // animation-timing-function: linear;
  // /*速度曲线*/
  // animation-iteration-count: infinite;
  // /*播放次数*/
  // animation-direction: alternate;
  // /*逆向播放*/
  // animation-play-state: running;
  /*正在运行*/
}

// @keyframes beat {
//     0% {
//         bottom: 16%;
//     }

//     100% {
//         bottom: 18%;
//     }
// }

.chatGPTBox img {
  width: 48px;
  cursor: pointer;
}

.animated {
  animation-duration: 1s;
  /*动画时间*/
  animation-fill-mode: both;
  /*播放后的状态*/
}

.animated {
  animation-iteration-count: infinite;
  /*动作循环的次数：infinite 无限循环*/
}

.animated {
  animation-duration: 4s;
  /*动画时间*/
}

.container {
  animation-name: container;
  /*动画的名称*/
  transform-origin: center bottom;
  /*设置动画旋转元素的基点为：居中靠下*/
  cursor: pointer;
}

@keyframes container {
  0%,
  100%,
  20%,
  50%,
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    /*贝塞尔曲线 ： X1 Y1 X2 Y2*/
    transform: translate3d(0, 0, 0);
    /*设置只在Z轴上移动*/
  }

  40%,
  43% {
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.common-page {
  padding: $MP16 0;
}

.mglass {
  position: relative;

  .el-card__body {
    background-position: center top;
    background-size: cover;

    &::after {
      // background-image: url(../assets/img/pb-bg2.png);
      // background-image: url(../assets/img/mglass2.png);
      background-position: center top;
      background-size: cover;
      background-attachment: fixed;
      // -webkit-filter: blur(150px);
      // -moz-filter: blur(150px);
      // -ms-filter: blur(150px);
      // -o-filter: blur(150px);
      // filter: blur(150px);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: rgba(255,255,255,0.1);
      z-index: -1;
    }
  }
}
.btn-portal {
  padding: 0 24px !important;
  height: 24px;
  float: left;
  // line-height: 24px;
}
</style>
