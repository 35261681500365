<template>
  <div class="header-right-group home">
    <span class="poc-size14 hrg-item"
      >{{ $t('dc:欢迎') }} <strong style="font-weight: 700">!</strong></span
    >
    <el-tooltip placement="top">
      <div slot="content" class="useInfo">
        <div>
          <span class="mb8">{{ $t('dc:代理商') }}:</span>
          <span>{{ userInfo.agencyName || '-' }}</span>
        </div>
        <div>
          <span>{{ $t('dc:客户') }}:</span>
          <span>{{ userInfo.clientName || '-' }}</span>
        </div>
      </div>
      <span style="font-size: 14px; font-weight: 400"> {{ userName }}</span>
    </el-tooltip>
    <!-- <el-tooltip v-show="showChatIcon" effect="dark" :content="$t('dc:EDGEAI')" placement="top">
      <el-button type="primary" @click="goChatGpt" circle class="margL32 hrg-item">
        <i class="poc-size14 iconfont icon-oneui-sso-chatrobot"></i>
      </el-button>
    </el-tooltip> -->

    <el-tooltip effect="dark" :content="$t('dc:公告列表')" placement="top">
      <el-button type="primary" @click="showHistoryLanch" circle class="margL32 hrg-item">
        <i class="poc-size14 iconfont icon-oneui-gonggao"></i>
      </el-button>
    </el-tooltip>

    <!-- <el-tooltip effect="dark" :content="$t('dc:用户反馈')" placement="top">
      <el-button type="primary" @click="goUserFreedBack" circle class="hrg-item">
        <i class="poc-size12 iconfont icon-oneui-feedback-center"></i>
      </el-button>
    </el-tooltip> -->

    <el-badge :hidden="notify == 0" :value="notify" class="item hrg-item">
      <el-tooltip effect="dark" :content="$t('dcH:用户组管理')" placement="top">
        <el-button type="primary" @click="goUserGroupList" circle>
          <i class="poc-size12 iconfont icon-oneui-mdm-client"></i>
        </el-button>
      </el-tooltip>
    </el-badge>
    <el-tooltip v-if="showUser" effect="dark" :content="$t('dc:管理员')" placement="top">
      <el-button type="primary" @click="goUserList" circle class="hrg-item">
        <i class="poc-size12 iconfont icon-oneui-marangement"></i>
      </el-button>
    </el-tooltip>

    <el-tooltip effect="dark" :content="$t('dc:用户中心')" size="small" placement="bottom-end">
      <el-dropdown trigger="click" class="hrg-item homeUser" @command="onCommand">
        <el-button type="primary" circle>
          <i class="poc-size12 el-icon-user-solid"></i>
        </el-button>
        <el-dropdown-menu
          slot="dropdown"
          class="dropdown-userinfo-popper"
          style="background: rgba(40, 40, 40, 1); color: #fff"
        >
          <!-- <el-dropdown-item command="info" disabled>{{ userName }}</el-dropdown-item> -->
          <el-dropdown-item v-show="userName !== null" command="showPassword"
            >{{ $t('dc:修改密码') }}
          </el-dropdown-item>
          <!-- <el-dropdown-item command="changeAgency"
            >{{ userLion !== '' ? $t('dc:修改代理') : $t('dc:修改品牌') }}
          </el-dropdown-item> -->
          <el-dropdown-item command="groupInfomation">{{ $t('dc:集团信息') }} </el-dropdown-item>
          <el-dropdown-item command="goUserFreedBack">{{ $t('dc:用户反馈') }} </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-tooltip>
    <!-- <el-button @click="switchLang" circle class="hrg-item">
      <span class="switch-lang">{{this.$i18n.locale === 'zh-cn' ? 'En' : '中'}}</span>
    </el-button> -->
    <el-button type="primary" @click="switchLang" circle style="margin-left: 10px">
      <span class="switch-lang">{{ this.$i18n.locale === 'zh-cn' ? 'En' : '中' }}</span>
    </el-button>
    <el-tooltip effect="dark" :content="$t('dc:帮助中心')" placement="bottom">
      <el-button @click="downLoadHandBook" circle class="hrg-item">
        <i class="poc-size12 iconfont icon-oneui-help"></i
      ></el-button>
    </el-tooltip>
    <el-tooltip effect="dark" :content="$t('dc:退出')" placement="top">
      <el-button type="primary" circle @click="logout">
        <i class="poc-size12 iconfont icon-oneui-logout"></i>
      </el-button>
    </el-tooltip>
    <div
      v-show="(!dialogVisible && isFirstLogin) || allowModelDelog"
      class="bgUser"
      @click="close"
    ></div>
    <div
      v-show="(!dialogVisible && isFirstLogin) || allowModelDelog"
      class="msgBox"
      :class="{ admin: !showUser }"
    >
      <el-alert type="warning" show-icon :closable="false">
        <template slot="title">Please enter Group Manager to select your group</template>
      </el-alert>
      <img class="el-input__icon" style="color: #fff" src="../assets/img/arrow.svg" alt />
    </div>
    <launchAnnouncementDialog
      v-if="showLaunchStatus"
      :visible.sync="showLaunchStatus"
      :launchDataList="launchDataList"
      :homeClass="'home'"
    ></launchAnnouncementDialog>
    <historyAnnouncementDialog
      v-if="showLaunchHistoryStatus"
      :visible.sync="showLaunchHistoryStatus"
      :historyDataList="historyDataList"
      :homeClass="'home'"
    ></historyAnnouncementDialog>
  </div>
</template>

<script>
import config from '@/api/config'
const { VUE_APP_AI } = process.env
import { mapGetters, mapActions } from 'vuex'
import launchAnnouncementDialog from '@/views/dialog/launchAnnouncementDialog'
import historyAnnouncementDialog from '@/views/dialog/historyAnnouncementDialog'
export default {
  name: 'HeaderRightGroup',
  components: {},
  props: {
    showUser: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showManagerStatus: {
      type: Boolean,
      default: false,
    },
    showModel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    launchAnnouncementDialog,
    historyAnnouncementDialog,
  },
  watch: {
    showManagerStatus(v) {
      this.initEvent()
    },
    showModel(v) {
      this.allowModelDelog = v
    },
  },
  data() {
    return {
      isFirstLogin: false,
      showLaunchHistoryStatus: false,
      showLaunchStatus: false,
      notify: '',
      allowModelDelog: this.showModel,
      launchDataList: {},
      historyDataList: [],
      showChatIcon: false,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    userName() {
      return this.userInfo ? this.userInfo.username : null
    },
    userLion() {
      return this.userInfo ? this.userInfo.lionId : null
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    ...mapActions(['clearUserInfo', 'updataLang']),
    async initEvent() {
      let { status, data } = await $Api.groupAdmin.getUserGroup()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.isFirstLogin = data.isFirstLogin
        this.notify = data.notify
        this.getAnnouncementDialog({ appName: this.$PROJECT_CODE })
        this.getAuthorityMsgData()
      }
    },
    // 获取弹窗信息
    async getAnnouncementDialog(params) {
      let { status, data } = await $Api.announcement.popupAnnouncement(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data == null) return
        this.launchDataList = data
        this.showLaunchStatus = true
      }
    },
    async getAuthorityMsgData() {
      let { status, data } = await $Api.chatGPT.getAuthority()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.showChatIcon = data.authorized
      }
    },
    goUserList() {
      this.$router.push({ name: 'authManagement.AccountList' })
    },
    goUserFreedBack() {
      this.$emit('showUserFreedBack')
    },
    showPassword() {
      this.$emit('showpassword')
    },
    showInfomation() {
      this.$emit('showGroupinfomation')
    },
    goUserGroupList() {
      this.$emit('showGroupManager')
      this.$emit('close')
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh-cn' ? 'en-us' : 'zh-cn'
      this.upDataLang()
    },
    /**
     * 存储用户语言
     */
    async upDataLang() {
      let { status } = await $Api.user.setLang({
        appName: this.$PROJECT_CODE,
        lang: this.$i18n.locale,
      })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.updataLang(this.$i18n.locale)
        this.$localStorage.setItem('lang', this.$i18n.locale)
      }
    },
    onCommand(v) {
      if (v === 'showPassword') this.showPassword()
      // if (v === 'changeAgency') this.$emit('changeAgency')
      if (v === 'groupInfomation') this.showInfomation()
      if (v === 'goUserFreedBack') this.goUserFreedBack()
    },
    downLoadHandBook() {
      window.open(config.SSO_HANDBOOK)
    },
    close() {
      this.allowModelDelog = false
      this.isFirstLogin = false
      this.$emit('closeHeader')
    },
    showHistoryLanch() {
      this.showLaunchHistoryStatus = true
    },
    goChatGpt() {
      let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      return window.open(`${VUE_APP_AI}?token=${token}`, '_blank')
    },
    /**
     * 登出
     */
    async logout() {
      const { status } = await $Api.user.logout()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.clearUserInfo()
        this.$sessionStorage.setItem(config.USER_TOKEN_KEY, '')
        this.$cookies.remove(config.COOKIE_TOKEN)
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/var.scss';
.header-right-group .user-login-btn.el-button {
  margin-right: 30px;
  background: transparent;
  color: #ffffff;
  border: none;
  a {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 14px;
    margin-left: 5px;
  }
}
.header-right-group .user-login-btn.el-button:hover {
  color: #ba9765;
  border: none;
}
.header-right-group {
  .hrg-item {
    margin-left: $MP8;
  }
  .hrg-item.margL32 {
    margin-left: 24px;
  }
  .switch-lang {
    width: 12px;
    align: center;
    display: inline-block;
  }

  .el-button {
    background: $--color-primary;
    color: #ffffff;
    border-color: transparent;
  }
}
.bgUser {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: 0;
  zoom: 1;
  background-color: black;
  opacity: 0.8;
  background-repeat: no-repeat;
}
.msgBox {
  //  display: none;
  position: absolute;
  right: 230px;
  top: 70px;
  opacity: 1;
  font-size: 12px;
  .el-alert--warning.is-light {
    background: #292929;
    color: #fff;
  }
  .el-alert {
    padding: 15px;
    border-radius: 2px;
  }
  .el-icon-warning {
    font-size: 32px;
    width: 32px;
    color: #292929;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
  }
  .el-alert__title {
    font-size: 12px;
    line-height: 12px;
  }
  img {
    position: absolute;
    top: -10px;
    right: -45px;
  }
}
.msgBox.admin {
  right: 194px;
}
.dropdown-userinfo-popper.el-dropdown-menu {
  border-color: transparent;
  .el-dropdown-menu__item {
    color: #fff;
  }
}
.useInfo span {
  display: inline-block;
  width: 50px;
  line-height: 1;
  // display: block;
}
.useInfo span.mb8 {
  margin-bottom: 8px;
}
.item {
  position: relative;
}
</style>
