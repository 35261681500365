<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="520px"
      top="14%"
      :close-on-click-modal="false"
      class="addAccount-detail-dialog"
      @close="closeAddEdit"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('dc:新增账户')" name="1">
          <el-form
            v-if="activeName === '1'"
            style="margin: 0 auto"
            label-position="right"
            label-width="130px"
            :inline="false"
            ref="accountFormData"
            :model="accountFormData"
            :rules="rules"
          >
            <el-form-item :label="$t('dc:账号名称')" prop="username" required>
              <el-input
                style="width: 374px"
                v-model="accountFormData.username"
                maxlength="100"
                :placeholder="$t('dc:例如:MurielLiu')"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('dc:邮箱')">
              <el-input
                v-model="accountFormData.email"
                maxlength="100"
                width="100%;"
                show-word-limit
                style="width: 374px"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('dc:品牌')" required prop="brandId">
              <el-select v-model="accountFormData.brandId" filterable :placeholder="$t('dc:请选择')" style="width:374px;">
                <el-option
                  v-for="(items,index) in userBrandList"
                   :key="index"
                  :label="items.brandName"
                  :value="items.brandId"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item :label="$t('dc:密码')" prop="password" required>
              <el-input
                v-model="accountFormData.password"
                maxlength="100"
                width="100%;"
                resize="none"
                show-word-limit
                style="width: 374px"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('dc:代理商')" prop="agencyId">
              <el-select
                v-model="accountFormData.agencyId"
                filterable
                :placeholder="$t('dc:请选择')"
                style="width: 374px"
              >
                <el-option
                  v-for="(items, index) in agencyList"
                  :key="index"
                  :label="items.name"
                  :value="items.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('dc:客户')" prop="clientId">
              <el-select
                v-model="accountFormData.clientId"
                filterable
                :placeholder="$t('dc:请选择')"
                style="width: 374px"
              >
                <el-option
                  v-for="(items, index) in clientList"
                  :key="index"
                  :label="items.name"
                  :value="items.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('dc:有效期')" prop="expireDate">
              <el-date-picker
                style="width: 374px"
                v-model="accountFormData.expireDate"
                type="date"
                value-format="yyyy-MM-dd"
                :placeholder="$t('dc:请选择')"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('dc:添加LION ID')" name="2">
          <el-form
            v-if="activeName === '2'"
            style="margin: 0 auto"
            label-position="right"
            label-width="130px"
            :inline="false"
            ref="lionIdFormData"
            :model="lionIdFormData"
            :rules="rules"
          >
            <el-form-item :label="$t('dc:账号名称')" prop="username" required>
              <el-input
                style="width: 374px"
                v-model="lionIdFormData.username"
                maxlength="100"
                :placeholder="$t('dc:例如:MurielLiu')"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="Lion ID" prop="lionId" required>
              <el-input
                style="width: 374px"
                v-model="lionIdFormData.lionId"
                maxlength="100"
                :placeholder="$t('dc:请输入')"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('dc:邮箱')" prop="email" required>
              <el-input
                v-model="lionIdFormData.email"
                maxlength="100"
                width="100%;"
                show-word-limit
                style="width: 374px"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('dc:代理商')" prop="agencyId">
              <el-select
                v-model="lionIdFormData.agencyId"
                filterable
                :placeholder="$t('dc:请选择')"
                style="width: 374px"
              >
                <el-option
                  v-for="(item, index) in agencyList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('dc:客户')" prop="clientId">
              <el-select
                v-model="lionIdFormData.clientId"
                filterable
                :placeholder="$t('dc:请选择')"
                style="width: 374px"
              >
                <el-option
                  v-for="(item, index) in clientList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer">
        <el-button class="marginR20" @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="info" @click="submitForm">{{ $t('dc:确认') }}</el-button>
      </div>
      <el-dialog
        width="30%"
        :visible.sync="innerVisible"
        append-to-body
        :close-on-click-modal="false"
        :show-close="false"
        :title="$t('dc:提醒')"
      >
        <div>{{ addTitle }}</div>
        <div slot="footer">
          <el-button @click="innerVisible = false" class="marginR20">{{ $t('dc:否') }}</el-button>
          <el-button class="btn-black" type="info" @click="subAddAccount">{{
            $t('dc:是')
          }}</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'adminDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    content: String,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    activeName(v) {
      switch (v) {
        case '1':
          this.lionIdFormData = {
            username: '',
            lionId: '',
            email: '',
            agencyId: '',
            clientId: '',
          }
          break
        case '2':
          this.accountFormData = {
            username: '',
            email: '',
            password: '',
            agencyId: '',
            clientId: '',
          }
          break
        default:
          break
      }
    },
  },
  data() {
    var accountNameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (!new RegExp('^[A-Z][a-z0-9_]+([A-Z]+[a-z0-9]+)+$').test(value)) {
        callback(new Error(rule.message || this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var emailRule = (rule, value, callback) => {
      const mailReg = '\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*'
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (value.length > 100) {
        new Error(this.$t('dc:仅支持100个字符'))
      }
      setTimeout(() => {
        if (new RegExp(mailReg).test(value)) {
          callback()
        } else {
          this.showEmail = false
          callback(new Error(this.$t('dc:请按规则输入')))
        }
      }, 100)
    }
    var passwordRule = (rule, value, callback) => {
      var Regx = /^[A-Za-z0-9*]+$/
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showPossword = false
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false
        callback(new Error(this.$t('dc:仅支持编号。大写和小写字母')))
      } else {
        this.showPossword = true
        callback()
      }
    }
    return {
      show: this.visible,
      titleMsg: this.title,
      innerVisible: false,
      accountFormData: {
        username: '',
        email: '',
        password: '',
        agencyId: '',
        clientId: '',
        expireDate: '',
      },
      lionIdFormData: {
        username: '',
        lionId: '',
        email: '',
        agencyId: '',
        clientId: '',
      },
      fromData: {},
      activeName: '1',
      addTitle: this.$t('dc:您是否确认添加此帐户?'),
      rules: {
        username: [{ validator: accountNameRule, trigger: 'blur' }],
        email: [{ validator: emailRule, trigger: 'blur' }],
        password: [{ validator: passwordRule, trigger: 'blur' }],
        lionId: [{ validator: passwordRule, trigger: 'blur' }],
        agencyId: [{ required: false, message: this.$t('dc:请按规则输入'), trigger: 'change' }],
      },
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() || time.getTime() > Date.now() + 365 * 24 * 60 * 60 * 1000
          )
        },
      },
    }
  },
  created() {},

  mounted() {},
  computed: {
    ...mapGetters(['agencyList', 'clientList']),
  },
  methods: {
    submitForm() {
      var formName = 'accountFormData'
      switch (this.activeName) {
        case '1':
          formName = 'accountFormData'
          this.fromData = this.accountFormData
          this.addTitle = this.$t('dc:您是否确认添加此帐户?')

          break
        case '2':
          formName = 'lionIdFormData'
          this.fromData = this.lionIdFormData
          this.addTitle = this.$t('dc:您是否确认添加此Lion ID?')

          break
        default:
          break
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.innerVisible = true
        } else {
          return false
        }
      })
    },
    changePickerData(v) {
      console.log(v)
    },
    async subAddAccount() {
      let { status } = await $Api.project.addAccount(this.fromData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$emit('queryApi')
      } else this.$emit('queryApi')
      this.innerVisible = false
    },

    closeAddEdit() {
      this.$emit('queryApi')
    },
  },
}
</script>
<style lang="scss">
.addAccount-detail-dialog .showEmail {
  font-size: 12px;
  display: block;
  text-align: left;
  margin: -18px 0px 10px 120px;
  text-decoration: underline;
  cursor: pointer;
}
.addAccount-detail-dialog .el-dialog .el-dialog__body {
  text-align: left;
  padding: 0;
}
.addAccount-detail-dialog {
  .el-tabs__nav {
    width: 172px;
    // display: flex;
    // float: none;
  }
  .el-tabs .el-tabs__item {
    // flex: 1;
    // text-align: center;
  }
}
</style>
