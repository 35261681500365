<template>
  <div class="markdown">
    <div id="vditor"></div>
  </div>
</template>
<script>
import Vditor from 'vditor'
import 'vditor/dist/index.css'
const { VUE_APP_SSOURL } = process.env
const { PocResetMessage } = $PCommon
export default {
  name: 'SSOMarkDown',
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default() {
        return 'markdown-editor-' + new Date() + ((Math.random() * 1000).toFixed(0) + '')
      },
    },
    isHideTools: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPin: {
      type: Boolean,
      required: false,
      default: true,
    },
    height: {
      type: String,
      required: false,
      default: '300px',
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    mode: {
      type: String,
      required: false,
      default: 'sv',
    },
  },

  data() {
    return {
      contentEditor: '',
    }
  },
  //mounted
  mounted() {
    this.contentEditor = new Vditor('vditor', {
      height: this.height,
      width: this.width,
      toolbarConfig: {
        pin: this.isPin,
        hide: this.isHideTools,
      },
      cache: {
        enable: false,
      },
      toolbar: [
        // 'emoji',
        'headings',
        'bold',
        // 'italic',
        // 'strike',
        // 'link',
        '|',
        'list',
        'ordered-list',
        // 'check',
        'outdent',
        'indent',
        '|',
        // 'quote',
        'line',
        'code',
        'inline-code',
        'insert-before',
        'insert-after',
        '|',
        'upload',
        // 'record',
        // {
        //   //自定义上传
        //   hotkey: '',
        //   name: 'upload',
        //   // tipPosition: "s",
        //   tip: '上传图片',
        //   className: 'right',
        // },
        'table',
        '|',
        'undo',
        'redo',
        'preview',
        // '|',
        // "fullscreen",
        // 'edit-mode',
        // {
        // name: 'more',
        // toolbar: [
        //"both",
        // 'code-theme',
        // 'content-theme',
        // 'export',
        // 'outline',
        // 'preview',
        //"devtools",
        // "info",
        //"help",
        // ],
        // },
      ],
      after: () => {
        this.contentEditor.setValue(this.value)
      },
      mode: this.mode || 'sv',
      image: {
        isPreview: false,
      },
      // theme: 'dark',
      preview: {
        mode: 'editor',

        actions: [],
      },
      //这里写上传
      upload: {
        accept: 'image/jpg, image/jpeg, image/png', //规定上传的图片格式
        url: `${VUE_APP_SSOURL}/api/security/admin/announcement/file-upload`, //请求的接口
        multiple: false,
        fieldName: 'file',
        headers: {
          'Access-Token': this.$sessionStorage.getItem('Access-Token'),
        },
        max: 20 * 1024 * 1024, //上传图片的大小
        // extraData: { 'access-token': '16A7EE8DA8534A2AAE386534E331D04C' }, //为 FormData 添加额外的参数
        // linkToImgUrl: 'https://ai-int.publicisgrowthos.com/api/edge-ai/file/manage/upload-image',
        filename(name) {
          return name
            .replace(/[^(a-zA-Z0-9\u4e00-\u9fa5\.)]/g, '')
            .replace(/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g, '')
            .replace('/\\s/g', '')
        },
        validate(files) {
          const isLt2M = files[0].size / 1024 / 1024 < 20
          if (!isLt2M) {
            console.log('error')
            //this.$message.error('')
          }
        },
        // 遍历文件上传并展示
        // async handler(files) {
        //   for (const file of files) {
        //     const { status, data } = await $Api.announcement.upAnnouncementImg({ file: file })
        //     // console.log(this.contentEditor)
        //     this.contentEditor.current?.insertValue(`![${file.name}](${data})`)
        //     // this.contentEditor?.insertValue(`![${name}](${res.uplaodRes})`) // 文本编辑器中插入图片
        //   }
        // },
        //上传图片回显处理
        format(files, responseText) {
          console.log(files, responseText)
          var self = this
          // var data = JSON.parse(responseText)
          let { status, data } = JSON.parse(responseText)
          //上传图片请求状态
          if (status == 0) {
            let succ = {}
            succ[files[0].name] = data
            //图片回显
            return JSON.stringify({
              data: {
                errFiles: [],
                succMap: succ,
              },
            })
          } else {
            PocResetMessage.warning('图片上传失败')
          }
        },
        error(msg) {
          console.log(msg + '上传失败了')
        },
      },
    })
  },
  methods: {
    getValue() {
      return this.contentEditor.getValue() //获取 Markdown 内容
    },
    getHTML() {
      return this.contentEditor.getHTML() //获取 HTML 内容
    },
    setValue(value) {
      return this.contentEditor.setValue(value) //设置 Markdown 内容
    },
    disabled() {
      return this.contentEditor.disabled() //设置 只读
    },
    toPreview() {
      var evt = document.createEvent('Event')
      evt.initEvent('click', true, true)
      this.contentEditor.vditor.toolbar.elements.preview.firstElementChild.dispatchEvent(evt)
    },
  },
}
</script>
