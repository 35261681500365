<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="610px"
      top="6%"
      :title="getTitle"
      :close-on-click-modal="false"
      class="addEidt-detail-dialog"
      @close="closeAddEdit"
    >
      <el-form
        style="margin: 0 auto"
        label-position="right"
        label-width="128px"
        :inline="false"
        ref="fromDate"
        :model="formData"
        :rules="rules"
      >
        <el-form-item :label="$t('dc:用户组名称')" prop="userGroupName" required>
          <el-input
            style="width: 450px"
            v-model="formData.userGroupName"
            maxlength="40"
            :placeholder="$t('dc:请输入')"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dc:描述')" prop="description" required>
          <el-input
            type="textarea"
            v-model="formData.description"
            maxlength="200"
            width="100%;"
            :minRows="8"
            class="groupDescription"
            resize="false"
            show-word-limit
            style="width: 450px"
            :placeholder="$t('dc:请输入')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dc:选择管理员')" prop="adminId" required>
          <el-select
            v-model="formData.adminId"
            remote
            filterable
            :remote-method="remoteMethod"
            :placeholder="$t('dc:请输入并选择')"
            style="width: 450px"
          >
            <el-option
              v-for="items in adminList"
              :key="items.id"
              :label="items.name"
              :value="items.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <i class="showEmail" @click="showSendAdminEmail">{{ invitationMsg }}</i>
        <el-form-item
          :label="$t('dc:选择成员')"
          style="margin-top: 20px"
          v-if="!this.rawData.userGroupId"
        >
          <!-- <kr-paging
              :boxTitle="[$t('dc:未选择'),$t('dc:已选择')]"
              :filter-placeholder="[$t('dc:请输入'),$t('dc:请输入')]"
              :async="true"
              :filterable = "true"
              :dataList="dataList"
              :selectedData="selectedData"
              :filter-method="filterChange"
              :pageSize="100"
              :showClearBtn="true"
              @onChange="onChange"
              :getPageData="getPageData"
            >
            </kr-paging> -->

          <!-- <el-transfer
                v-model="selectedData"
                :filterable = "true"
                :titles="[$t('dc:未选择'),$t('dc:已选择')]"
                :filter-placeholder="$t('dc:请输入')"
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}'
                }"
                @change="handleChange"
                :data="dataList">
                <span slot-scope="{ option }">{{ option.label }}</span>
              </el-transfer> -->
          <div class="selectBox">
            <div class="selectClassBox">
              <el-select
                v-model="adminValue"
                filterable
                multiple
                remote
                reserve-keyword
                :placeholder="$t('dc:请选择')"
                style="width: 424px"
                popper-class="adminSelect"
                :remote-method="remoteMethodAll"
                @change="clearAadmin"
              >
                <el-option
                  v-for="(items, index) in allAdminList"
                  :key="index"
                  :label="items.label"
                  :value="items.id"
                >
                  <div class="optionBox" @click="changeAdmin(items)">
                    <span style="float: left">{{ items.label }}</span>
                    <i
                      v-if="!items.disabled"
                      style="float: right; color: #8492a6; font-size: 13px"
                      class="el-icon-plus"
                    ></i>
                    <i
                      v-else
                      style="float: right; color: #8492a6; font-size: 13px"
                      class="el-icon-minus"
                    ></i>
                  </div>
                  <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ items.id }}</span> -->
                </el-option>
              </el-select>
              <i class="el-icon-search selectSearch"></i>
            </div>
            <div class="userAdminlist">
              <ul>
                <li v-for="(item, index) in memberList" :key="index">
                  <span>{{ item.label }} </span
                  ><i class="el-icon-close" @click="delectAdmin(item)"></i>
                </li>
              </ul>
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('dc:有效期')" prop="expireDate">
          <el-date-picker
            style="width: 100%"
            v-model="formData.expireDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('dc:请选择')"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <i v-if="!this.rawData.userGroupId" class="showEmail" @click="showSendEmail">{{
          invitationMsg
        }}</i>
      </el-form>

      <div slot="footer">
        <el-button class="marginR20" type @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="info" @click="submitForm('fromDate')">{{
          $t('dc:确认')
        }}</el-button>
      </div>
    </el-dialog>
    <groupDailog
      v-if="showEmailDialog"
      :emailType="emailType"
      :visible.sync="showEmailDialog"
      :title="emailtitle"
      @closeEmail="closeEmail"
    ></groupDailog>
    <UsuallyDialog
      v-if="showGroupDialog"
      :visible.sync="showGroupDialog"
      :title="groupTitle"
      :content="groupContent"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>
<script>
import groupDailog from '@/views/dialog/groupDailog'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'adminDialog',
  components: {
    groupDailog,
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rawData: Object,
    title: String,
    content: String,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  data() {
    var userGroupNameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (!new RegExp('^([A-Z].*)').test(value)) {
        callback(new Error(rule.message || this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var adminIdRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var descriptionRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    return {
      show: this.visible,
      titleMsg: this.$t('dc:新增用户组'),
      contentMsg: this.content,
      groupTitle: this.$t('dc:提醒'),
      groupContent: this.$t('dc:您确认要添加此组吗?'),
      showEmailDialog: false,
      showGroupDialog: false,
      emailtitle: this.$t('dc:邮箱'),
      invitationMsg: this.$t('dc:没有结果？请邀请您要找的人注册'),
      adminList: [],
      dataList: [],
      selectedData: [],
      memberList: [],
      allAdminList: [],
      adminValue: '',
      formData: {
        userGroupId: this.rawData.userGroupId,
        userGroupName: '',
        adminId: '',
        description: '',
        memberIdList: [],
        expireDate: '',
      },
      groupData: this.rawData,
      showAllUser: false,
      emailType: 0,
      itemIndex: -1,
      rules: {
        userGroupName: [{ validator: userGroupNameRule, trigger: 'blur' }],
        description: [{ validator: descriptionRule, trigger: 'blur' }],
        adminId: [{ validator: adminIdRule, trigger: 'blur' }],
      },
      wornMsg: this.$t('dc:注意每次最多同时邀请5位同事'),
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() || time.getTime() > Date.now() + 365 * 24 * 60 * 60 * 1000
          )
        },
      },
    }
  },
  computed: {
    getTitle() {
      return this.rawData.userGroupId ? this.$t('dc:编辑用户组') : this.$t('dc:新增用户组')
    },
  },
  created() {
    if (this.rawData.userGroupId) {
      this.getPromiseGroupDat({ userGroupId: this.rawData.userGroupId })
      this.getGroupData({ userGroupId: this.rawData.userGroupId })
    }
    // if (this.rawData.userGroupId) {
    //
    //   Promise.all([
    //     this.getPromiseAllUserList(),
    //     this.getPromiseGroupDat({ userGroupId: this.rawData.userGroupId }),
    //   ]).then((res) => {
    //     if (res[0].status == res[1].status) {
    //       const _data1 = JSON.parse(JSON.stringify(res[0].data))
    //       const _data2 = JSON.parse(JSON.stringify(res[1].data.memberList))
    //       _data1.map((item) => {
    //         _data2.map((items) => {
    //           if (item.id === items.id) return (item.disabled = true)
    //         })
    //       })
    //       this.allAdminList = _data1
    //     }
    //   })
    // } else this.getAdminListData()
  },
  mounted() {
    // this.initEvent()
  },
  methods: {
    initEvent() {
      this.getAllUserList()
    },
    // 获取adminlist
    async getAdminListData(params) {
      let { status, data } = await $Api.group.getUserList(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.adminList = data
      }
    },
    // 去获取全部用户信息
    async getAllUserList(param) {
      let { status, data } = await this.getPromiseAllUserList(param)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.allAdminList = data
      }
    },
    // 获取 Promise的全部用户信息
    async getPromiseAllUserList(params) {
      return new Promise((resolve, reject) => {
        resolve($Api.group.getUserSelectList(params))
      })
    },
    // 获取 Promise获取组详细信息
    async getPromiseGroupDat(params) {
      return new Promise((resolve, reject) => {
        resolve($Api.group.getGrouoInfo(params))
      })
    },
    // 获取组详细信息
    async getGroupData(params) {
      let { status, data } = await this.getPromiseGroupDat(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.formData.userGroupName = data.userGroupName
        this.formData.adminId = data.admin.id
        this.formData.description = data.description
        this.formData.expireDate = data.expireDate
        this.memberList = data.memberList
        this.getAdminListData({ username: data.admin.name })
      }
    },
    remoteMethod(value) {
      if (!value) return
      this.getAdminListData({ username: value })
    },
    remoteMethodAll(value) {
      if (!value) return
      this.getAllUserList({ username: value })
    },
    removeList() {
      this.adminList = []
    },
    showSendAdminEmail() {
      this.emailType = 1
      this.showEmailDialog = true
    },
    showSendEmail() {
      this.emailType = 2
      this.showEmailDialog = true
    },
    closeEmail() {
      this.showEmailDialog = false
    },
    closeAddEdit() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    onChange(val) {
      this.formData.memberIdList = val
    },
    changeAdmin(value) {
      let dataIndex = this.getIndexOf(this.memberList, JSON.parse(JSON.stringify(value)))
      if (dataIndex > -1) this.memberList.splice(dataIndex, 1)
      else this.memberList.push(value)
      this.allAdminList.map((item) => {
        item.disabled = false
      })
      this.allAdminList.map((item) => {
        this.memberList.map((items) => {
          if (item.id === items.id) item.disabled = true
        })
      })
    },
    clearAadmin() {
      this.adminValue = ''
    },
    getIndexOf(dataList, value) {
      this.itemIndex = -1
      dataList.map((item, index) => {
        if (item.id * 1 === value.id * 1) return (this.itemIndex = index)
      })
      return this.itemIndex
    },
    delectAdmin(value) {
      this.memberList.map((item, index) => {
        if (value.id === item.id) {
          return this.memberList.splice(index, 1)
        }
      })
      this.allAdminList.map((item) => {
        item.disabled = false
      })
      this.allAdminList.map((item) => {
        this.memberList.map((items) => {
          if (item.id === items.id) item.disabled = true
        })
      })
    },
    confrom() {
      if (this.rawData.userGroupId) {
        this.editGroup(this.formData)
      } else this.addNewGroup(this.formData)
    },
    // 异步获取分页数据 待选区点击上一页/下一页执行
    async getPageData(pageIndex, pageSize) {
      // 掉接口请求数据
      const { data } = await $Api.group.getUserSelectList({
        pageIndex: pageIndex,
        pageSize: 1000,
      })

      // 将 resData 的数据结构处理成如 dataList、selectedData 一样
      return data
    },
    // 异步搜索的方法配置如下 搜索框失焦或回车执行
    async getSearchData(keyword, pageIndex, pageSize) {
      // 掉接口请求数据
      const { status, data } = await $Api.group.getUserSelectList({ username: keyword })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.dataList = data
      }
      return this.dataList
    },
    submitForm(fromDate) {
      this.$refs[fromDate].validate((valid) => {
        if (valid) {
          this.formData.memberIdList = []
          if (this.rawData.userGroupId) {
            this.groupContent = this.$t('dc:您确认要编辑此组吗?')
          } else {
            this.memberList.map((item) => {
              this.formData.memberIdList.push(item.id)
            })
            this.groupContent = this.$t('dc:您确认要添加此组吗?')
          }
          this.showGroupDialog = true
        }
      })
    },
    //添加group
    async addNewGroup(params) {
      let { status, data } = await $Api.group.addGroup(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$emit('update:visible', false)
        this.$emit('queryApi')
        this.show = false
      }
    },

    //穿梭搜索
    filterChange(query, item) {
      //自定义搜索，从当前数组变量中过滤，再渲染回组件
      var currentDatas = this.sourceDatas.filter(
        function (val) {
          return val.obj.sectionName.indexOf(query) > -1 && !this.formDatas.river.includes(val.key)
        }.bind(this),
      )
      if (currentDatas.length != this.currentDatas.length) {
        this.currentDatas = currentDatas
        this.handleCurrentChange(1)
      }
    },
    // 修改用户组
    async editGroup(params) {
      let { status, data } = await $Api.group.editGroup(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
        this.$emit('update:visible', false)
        this.$emit('queryApi')
        this.show = false
      }
    },
    handleChange() {},
  },
}
</script>
<style lang="scss">
.addEidt-detail-dialog .showEmail {
  font-size: 12px;
  display: block;
  text-align: left;
  margin: 0 0px 10px 130px;
  color: #909399;
  // text-decoration: underline;
  font-style: normal;
  cursor: pointer;
}
.addEidt-detail-dialog .el-dialog .el-dialog__body {
  text-align: left;
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
  }
  .selectBox {
    width: 450px;
    border-radius: 14px;
    margin-top: -10px;
  }
  .selectClassBox {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #dcdfe6;
    position: relative;
    padding: 10px;
    background: #f1f1f1;
    .selectSearch {
      font-size: 12px;
      position: absolute;
      top: 19px;
      right: 26px;
      z-index: 2;
    }
  }
  .userAdminlist ul {
    position: relative;
    margin-top: -2px;
    border: 1px solid #dcdfe6;
    border-top: 0;
    width: 448px;
    height: 152px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: scroll;
    // padding: 6px 0;
    padding-bottom: 10px;
    text-align: center;
    li {
      width: 40.5%;
      padding: 0px 10px;
      margin: 10px 10px 0;
      border: 1px solid #ccc;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f1f1f1;
      font-size: 12px;
      height: 26px;
      line-height: 26px;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      i {
        cursor: pointer;
        margin-left: 6px;
      }
    }
    li:hover {
      background-color: rgba(72, 227, 10, 0.1);
    }
  }

  //滚动条的宽度
  .userAdminlist ul::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    visibility: hidden;
  }

  // // 滚动条的滑块
  .userAdminlist ul::-webkit-scrollbar-thumb {
    background-color: #59e200;
    border-radius: 4px;
    // visibility: hidden;
  }

  .krry-main {
    text-align: left;
    margin-bottom: 20px;
    .el-checkbox__label {
      padding-left: 20px;
    }
    .el-transfer-panel__filter .el-input__inner::-webkit-input-placeholder {
      color: rgba($--color-primary, 0.3);
      font-size: 12px;
    }
    .district-panel .el-transfer-panel__header .el-checkbox__label {
      padding-left: 40px;
    }
  }
  .vip-footer {
    display: none;
  }
  p.no-data {
    display: none;
  }
  .groupDescription .el-textarea__inner {
    min-height: 80px !important;
  }
  .district-panel {
    width: 220px;
  }
  .district-panel .el-transfer-panel__body {
    height: 260px;
  }
  .district-panel .el-checkbox-group {
    height: 200px;
    overflow: scroll;
  }
  /* 设置滚动条的样式 */
  .el-checkbox-group::-webkit-scrollbar {
    width: 4px;
  }
  /* 滚动槽 */
  .el-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  .el-checkbox-group::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }
  .el-textarea .el-input__count {
    background: transparent;
  }
}
</style>
