<template>
  <div class="login-page">
    <div v-if="!isMebile" class="user-login login-scale">
      <video
        muted
        autoplay
        loop
        object-fit
        id="video-tvc"
        src="../assets/video/login.mp4"
        class="video-tvc"
      >
        抱歉，您的浏览器不支持内嵌视频
      </video>
      <div class="logBox">
        <div></div>
        <!-- <img class="logLion" src="../assets/img/log.png" alt> -->
        <img class="logo" src="../assets/img/logLion.png" alt />
      </div>
      <div class="login-box">
        <dl class="login-box-card">
          <dt class="login-box-header">
            <h1 class="login-box-header-welcome">WELCOME</h1>
            <div class="tab-box">
              <el-tabs v-model="activeName" :stretch="true" @tab-click="handleClick">
                <el-tab-pane label="LION LOGIN" name="first"></el-tab-pane>
                <el-tab-pane label="ACCOUNT LOGIN" name="second"></el-tab-pane>
              </el-tabs>
            </div>
            <!-- <img class="login-box-logo" :src="`${staticPath}/logo_main.gif`" />
            <img :src="`${staticPath}/logo_title.png`" />-->
          </dt>
          <dd class="login-box-body">
            <transition name="moveR">
              <div v-if="activeName == 'first'" class="login-box-body-item">
                <div class="login-box-row lionid">
                  <el-button
                    size="small"
                    class="user-login-btn btn-green"
                    type="primary"
                    @click="goLionid"
                    >LOGIN WITH LION ID</el-button
                  >
                </div>
              </div>
            </transition>
            <transition name="moveL">
              <div
                v-if="activeName == 'second'"
                class="login-box-body-item"
                style="position: absolute; top: 0; left: 0; width: 100%"
              >
                <div class="login-box-row">
                  <el-input
                    size="small"
                    placeholder="account/e-mail"
                    prefix-icon="iconfont icon-oneui-user-thick"
                    v-model="userInfo.account"
                  />
                </div>
                <div class="login-box-row">
                  <el-input
                    show-password
                    autocomplete="new-password"
                    size="small"
                    placeholder="password"
                    prefix-icon="iconfont icon-oneui-pass-thick"
                    v-model="userInfo.password"
                    @keyup.enter.native="onLogin"
                  />
                </div>
                <div class="login-box-row sumbit">
                  <el-button
                    size="small"
                    type="primary"
                    class="user-login-btn btn-green"
                    @click="onLogin"
                    >Sign In
                  </el-button>
                  <div>
                    <a href="javascript:;" @click.stop="goApply">Create Account</a>
                    <a href="javascript:;" @click.stop="goPassword">Forget Password?</a>
                  </div>
                </div>
              </div>
            </transition>
          </dd>
        </dl>
      </div>
      <div class="record">
        2018 - {{ nowYear }} PublicisGrowthOS.
        <img class="icpImg" src="@/assets/img/icpicon.png" alt="" />
        <a
          style="margin-right: 16px"
          href="https://beian.mps.gov.cn/#/query/webSearch?code=31010602008641"
          rel="noreferrer"
          target="_blank"
          >沪公网安备31010602008641号</a
        >
        <!-- <img class="icpImg" src="@/assets/img/icpicon.png" alt="" /> -->
        <a href="https://beian.miit.gov.cn" target="_blank"> 沪ICP备2021007762号-1</a>
      </div>
      <el-dialog
        :visible.sync="showChangePassword"
        width="32.5%"
        top="10%"
        title="Reset Password"
        :close-on-click-modal="false"
        class="acctount-detail-dialog home"
      >
        <el-form
          :model="resetDateForm"
          ref="resetDateForm"
          :rules="rulesList"
          hide-required-asterisk
          label-width="60px"
          label-position="right"
          class="demo-dynamic"
          style="margin-bottom: 0"
        >
          <el-form-item label-width="60px" label="Name" prop="username" class="marginB50">
            <el-input
              class="input-black"
              v-model="resetDateForm.username"
              maxlength="100"
              show-word-limit
            ></el-input>
            <span v-show="showName" class="el-form-item__error fontS12"
              >Please input your name as exampled: MurielLiu</span
            >
          </el-form-item>
          <el-form-item label-width="60px" prop="email" label="E-mail" class="">
            <el-input
              class="input-black"
              v-model="resetDateForm.email"
              maxlength="100"
              show-word-limit
            ></el-input>
            <span v-show="showEmail" class="el-form-item__error fontS12">xxxxxxx@xxxx.com</span>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button class="btn-green" type="primary" @click="changPassword('resetDateForm')"
            >Confirm</el-button
          >
        </span>
      </el-dialog>
      <accountPassword
        v-if="dialogAccoutPassword"
        :visible.sync="dialogAccoutPassword"
        :accountname="userData.username"
        @changePassword="changePassword"
      ></accountPassword>
    </div>
    <LoginMebile v-else />
  </div>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import config from '@/api/config'
import { mapActions } from 'vuex'
const { VUE_APP_BASEURL } = process.env
import accountPassword from './dialog/accountPassword'
import createRsa from '@/utils/encryption'
import { isMobilePhone } from '@/utils/common'
import LoginMebile from './LoginMebile'

export default {
  name: 'UserLogin',

  components: {
    accountPassword,
    LoginMebile,
  },

  data() {
    var nameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showName = false
      } else if (!new RegExp('[A-Z]').test(value)) {
        this.showName = false
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        this.showName = true
        callback()
      }
    }
    var emailRoute = (rule, value, callback) => {
      const mailReg = '\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*'
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showEmail = false
      } else if (value.length > 100) {
        this.showEmail = false
        callback(new Error('Only support 100 characters;'))
      }
      setTimeout(() => {
        if (new RegExp(mailReg).test(value)) {
          this.showEmail = true
          callback()
        } else {
          this.showEmail = false
          callback(new Error(this.$t('dc:请按规则输入')))
        }
      }, 100)
    }
    return {
      isMebile: false,
      activeName: 'first',
      staticPath: `/static`,
      userInfo: {
        account: '',
        password: '',
        callbackUrl: '',
        appName: '',
      },
      show: false,
      showName: true,
      showEmail: true,
      showChangePassword: false,
      resetDateForm: {
        username: '',
        email: '',
      },
      rulesList: {
        username: [{ validator: nameRule, trigger: 'change' }],
        email: [{ validator: emailRoute, trigger: 'blur' }],
      },
      userData: '',
      dialogAccoutPassword: false,
      nowYear: '',
    }
  },

  mounted() {
    this.$cookies.remove('login_token')
    this.$cookies.remove('userToken')
    this.userInfo.callbackUrl = this.$route.query.callbackUrl || window.location.href
    this.userInfo.appName = this.$route.query.appName || this.$PROJECT_CODE
    // document.getElementById('video-tvc').play();
  },

  created() {
    this.isMebile = isMobilePhone()
    let nowDate = new Date()
    this.nowYear = nowDate.getFullYear()
  },

  methods: {
    ...mapActions(['updateUserInfo']),
    async onLogin() {
      // this.userInfo.password = createRsa(this.userInfo.password);
      let userParams = {
        account: this.userInfo.account,
        password: createRsa(this.userInfo.password),
        callbackUrl: this.userInfo.callbackUrl,
        appName: this.userInfo.appName,
      }
      const { data, status, msg } = await $Api.user.login(userParams)
      if (status == 0) {
        this.$gtag &&
          this.$gtag.config({
            send_page_view: false,
            lionId: data.lionId,
          })
        this.userData = data
        this.updateUserInfo(data)
        this.$localStorage.setItem('lang', data.lang)
        let token = data.callbackUrl.split('token=')[1]
        this.$sessionStorage.setItem(config.USER_TOKEN_KEY, token)
        // this.$cookies.set(config.COOKIE_TOKEN, token);
        const callbackUrl = data.callbackUrl
        this.loginCountShowPassword(data)
      } else {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error',
        })
      }
    },
    async loginCountShowPassword(params) {
      const { status, data } = await $Api.user.loginCount(params.username)
      if (status == config.RESPONSE_STATUS.SUCCESS && data.isFirstTime) {
        this.dialogAccoutPassword = true
      } else {
        window.location.href = params.callbackUrl
      }
    },
    goLionid() {
      const paramStr = `callbackUrl=${encodeURIComponent(this.userInfo.callbackUrl)}&appName=${
        this.userInfo.appName
      }`
      window.location.href = `${$Api.user.goLionid()}?${paramStr}`
    },
    // createRsa(password) {
    //   let jsencrypt = new JSEncrypt();
    //   jsencrypt.setPublicKey(config.AESPUBLIC_KEY);
    //   let rsaData = jsencrypt.encrypt(password);
    //   return encodeURIComponent(rsaData);
    // },
    handleClick(tab, event) {},
    goApply() {
      this.$router.push({ name: 'accountUser' })
    },
    goPassword() {
      this.showChangePassword = true
    },
    changPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changForgetPwd(this.resetDateForm)
        } else {
          return false
        }
      })
    },
    async changForgetPwd(params) {
      const { status, msg } = await $Api.user.forgetPwd(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.resetDateForm.username = ''
        this.resetDateForm.email = ''
        this.$message({
          showClose: false,
          message: msg || this.$t('dc:已发送重置邮件给到管理员，请等待审批邮件通知！'),
          type: 'success',
        })
      }
      this.showChangePassword = false
    },
    async changePassword(data) {
      this.userInfo.password = ''
      this.dialogAccoutPassword = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/var.scss';
$text-color: #fff;

.login-page {
  width: 100%;
  height: 100%;
}

.moveR-enter-active,
.moveR-leave-active {
  transition: all 0.4s linear;
}

.moveR-enter {
  opacity: 0;
  transform: translateX(-50%);
}

.moveR-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.moveR-leave {
  opacity: 1;
  transform: translateX(0);
}

.moveR-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}

.moveL-enter-active,
.moveL-leave-active {
  transition: all 0.4s linear;
}

.moveL-enter {
  opacity: 0;
  transform: translateX(50%);
}

.moveL-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.moveL-leave {
  opacity: 1;
  transform: translateX(0);
}

.moveL-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

.logBox {
  position: fixed;
  top: 20px;
  height: 60px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    margin: 20px;
  }

  img.logo {
    height: 60px;
  }

  img.logLion {
    height: 40px;
  }
}

// .logo {
//   display: block;
//   width: 90px;
//   position: fixed;
//   top: 5%;
//   right: 5%;
//   z-index: 10;
// }
// .logLion{
//   display: block;
//   width: 90px;
//   position: fixed;
//   top: 10%;
//   left: 5%;
//   z-index: 10;
// }
.user-login {
  background: #000;
  position: relative;
  width: 100%;
  height: 100%;

  // .login-box-header-welcome {
  //   font-family: "tradeGothicLHExtended" !important;
  // }

  .login-video {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*这里是关键*/
  }

  .video-tvc {
    // width: 100%;
    height: 100%;
    object-fit: fill;
    /*这里是关键*/
  }

  .login-box {
    position: fixed;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);

    input {
      -webkit-text-fill-color: #fff;
    }

    .login-box-card {
      width: 100%;
      height: 100%;
      background: transparent;
      // background: rgba(200, 200, 200, 0.2);
      // background: rgba(7, 15, 51, 0.8);
      // background:rgba(186, 151, 101, 0.2);
    }
  }

  .login-box-header {
    color: $--color-primary;
    text-align: center;
    font-weight: bold !important;
    margin-bottom: $MP16;

    h1 {
      font-weight: 600;
      margin-bottom: 0;
      color: #fff;
      font-size: 100px;
    }

    .tab-box {
      width: 300px;
      margin: 0 auto;
    }

    .el-tabs__item {
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
      margin-bottom: 8px;
      opacity: 0.4;
      font-size: 16px;
      color: #ffffff;
    }

    // .el-tabs__active-bar{
    //   width: 100% !important;
    //   transform: translateX(-50%) !important;
    // }
    .el-tabs__active-bar {
      height: 2px;
    }

    .el-tabs__item.is-active {
      opacity: 1;
      font-size: 16px;
      color: #ffffff;
    }

    .login-box-header-title {
      font-size: 34px;
    }

    .login-box-header-title-vice {
      font-size: 16px;
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
      opacity: 0.7;
    }
  }

  .login-box input {
    -webkit-text-fill-color: #fff;
  }

  .login-box-body {
    width: 228px;
    height: 250px;
    margin: 0 auto;
    position: relative;

    .login-box-body-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .login-box-row {
    align-items: center;
    margin-bottom: $MP16;

    .el-input {
      width: 100%;

      .el-input__inner {
        color: #fff;
        background: transparent !important;
        border-radius: 16px;
      }

      .el-input__icon {
        font-size: 14px;
        margin-left: 5px;
      }
    }

    // .el-button {
    //   border-radius: 16px;
    // }

    &.sumbit {
      padding-top: $MP8;

      // opacity: 0.7;
      div {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
      }

      a {
        color: $--color-font;
        font-size: 12px;
      }

      a:hover {
        color: $--color-primary-deputy;
      }
    }

    &.lionid {
      text-align: center;
    }

    .el-input__icon {
      color: #fff;
    }
  }

  .user-login-btn {
    display: block;
    font-weight: 400;
    width: 100%;
    font-size: 12px;
  }

  .user-login-lionid {
    display: block;
    background: $poc-color-primary-deputy;
    width: 100%;
    color: $text-color;
  }

  .user-login-bg {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
  }

  .record {
    color: #fff;
    position: fixed;
    font-size: 12px;
    bottom: 2.5%;
    right: 2%;
    display: flex;
    align-items: center;

    img {
      margin: 0 4px 0 6px;
    }

    a {
      display: flex;
      align-items: center;
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active,
    a:focus {
      color: #fff;
    }
  }

  .icpImg {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  .acctount-detail-dialog {
    .el-dialog {
      background: rgba(60, 60, 60, 0.8);
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }

    .el-dialog__title {
      font-size: 21px;
      font-weight: normal;
    }

    .el-dialog__body {
      font-size: 14px;
      color: #fff;
    }

    .el-dialog__body,
    .el-dialog__footer {
      text-align: center;
    }

    .el-dialog__body {
      // margin: 4px 0
    }

    .el-dialog__footer {
      text-align: right;
    }

    .el-form-item__label {
      color: #fff;
    }
  }

  .marginB50 {
    margin-bottom: 50px;
  }
}
</style>
